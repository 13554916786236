import AbsModule from '../common/AbsModule';

class Character extends AbsModule {
  startSetting() {
    jQuery('#nc-cnb').on('click', '.ncc-character-panel .ncc-character-panel-close', e => {
      jQuery('.ncc-character-panel').removeClass('is-active');
      return false;
    });
  }

  makeMarkup() {
    return `
        <div class="ncc-character-panel">
            <div class="ncc-character-panel-wrap">
                <a href="#" class="ncc-character-panel-close">${cnbL10NData.Common.Close}</a>
                <div class="ncc-character-panel-header">${cnbL10NData.Mycharacter.SelectMainCharacter}</div>
                <div class="ncc-character-list-wrap">
                    <ul class="ncc-character-list"></ul>
                </div>
            </div>
        </div>`;
  }
}

export default Character;
