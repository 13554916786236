import AbsModule from '../common/AbsModule';
import Templater from '../common/templater';
import _find from 'lodash/find';

class LnbDropdown extends AbsModule {
  constructor(_prefix, _data, _isShow = true) {
    super(_data, _isShow);
    this.prefix = `${_prefix}-dropdown`;
  }

  startSetting() {
    const $dropdown = jQuery(`.ncc-dropdown.${this.prefix}`);
    this.$dropdown = $dropdown;

    const dropdownItemHeight =
      parseInt(
        $dropdown
          .find('.dropdown-item')
          .first()
          .css('height'),
        10,
      ) + 3;

    this.dropdownMenuHeight = this.itemSize * (dropdownItemHeight + 1) - 1;

    /*switch (this.actionType) {
      case 'click':
        break;
      case 'hover':
        break;
    }*/

    $dropdown.find('.dropdown-toggle.btn').click(() => {
      this.toggle();
      return false;
    });
  }

  toggle() {
    const $toggleBtn = this.$dropdown.find('.dropdown-toggle.btn');
    const $menu = this.$dropdown.find('.dropdown-menu');
    $toggleBtn.toggleClass('is-active');
    $menu.toggleClass('is-active');

    const isActive = $toggleBtn.hasClass('is-active');
    $toggleBtn.attr('aria-expanded', isActive);
    $menu.find('.dropdown-menu-wrap').css('max-height', isActive ? this.dropdownMenuHeight : 0);

    if (isActive) {
      jQuery(document).on(`click.${this.prefix}`, this.toggle.bind(this));
    } else {
      jQuery(document).off(`click.${this.prefix}`);
    }
  }

  makeMarkup() {
    const { lnbDropdown = {} } = this.moduleData;
    const { items, action } = lnbDropdown;

    this.actionType = action;
    this.itemSize = items.length;

    const targetItem = _find(items, o => o.selected);
    const $toggleBtn = `
    <a class="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span class="dropdown-toggle-arrow"></span>
      <span class="dropdown-item-img" style="background-image: url(${targetItem.logo})">Dropdown link</span>
    </a>
    `;

    const itemTemplate = Templater`
        <a class="dropdown-item" ${'href.link'} ${'target.target'}>
            <span class="dropdown-item-img" style="background-image: url(${'logo'})">${'title'}</span>
        </a>`;
    const $itemButtons = items.map(o => itemTemplate(o)).join('');

    return `
    <div class="ncc-dropdown ${this.prefix}">
      ${$toggleBtn}
      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <div class="dropdown-menu-wrap">
          ${$itemButtons}
        </div>
      </div>
    </div>`;
  }
}

export default LnbDropdown;
