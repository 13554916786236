import AbsModule from '../common/AbsModule';
import LnbDropdown from '../components/LnbDropdown';
import Lnb from './Lnb';
import AppDownload from './AppDownload';
import ServiceList from './ServiceList';
import Banner from './Banner';
import isEmpty from 'lodash/isEmpty';
import { isMobile } from '../common/util';

class LeftMain extends AbsModule {
  constructor(_config) {
    super(_config);

    this._lnb = new Lnb(_config);
    this._appDownload = new AppDownload(
      _config.appDownloadData,
      !isEmpty(_config.appDownloadData) && _config.appDownloadData.isShow,
    );
    this._serviceList = new ServiceList(
      _config.serviceListData,
      !isEmpty(_config.serviceListData) &&
        (_config.serviceListData.isServiceShow ||
          _config.serviceListData.isPcShow ||
          _config.serviceListData.isMobileShow) &&
        _config.useLeftMenu,
    );
    this._banner = new Banner(_config.bannerData, !isEmpty(_config.bannerData) && _config.bannerData.isShow);
    this._lnbDropdown = new LnbDropdown('left-panel', _config, !isEmpty(_config.lnbDropdown));
  }

  startSetting() {
    jQuery('#nc-cnb').on('click', '.ncc-left-panel-close', e => {
      this.dim.openClose(false);
      return false;
    });

    this._lnb.start();
    this._appDownload.start();
    this._serviceList.start();
    this._banner.start();
    this._lnbDropdown.start();

    jQuery(this.dim).on('open', (e, data) => {
      if (data.show && data.pos === 'left') {
        this.createIScroll();
      }
    });

    const $panel = jQuery('.ncc-left-panel');
    if ($panel.length) {
      const panel = $panel[0];
      const stopEvtAction = e => {
        e.preventDefault();
      };
      panel.addEventListener('touchmove', stopEvtAction, { passive: false });
    }
  }

  createIScroll() {
    if (this.iScroll) return;
    const isMo = isMobile;
    this.iScroll = new IScroll('.ncc-left-panel-content', {
      scrollbars: true,
      mouseWheel: true,
      interactiveScrollbars: true,
      shrinkScrollbars: 'scale',
      disableMouse: !isMo,
      disablePointer: !isMo,
      fadeScrollbars: true,
      // preventDefault: true,
      preventDefaultException: { tagName: /^(INPUT|TEXTAREA|BUTTON|SELECT|A|LI|SPAN|IMG|STRONG)$/ },
    });
  }

  destroy() {
    if (this.iScroll) {
      this.iScroll.destroy();
      this.iScroll = null;
    }
  }

  makeMarkup() {
    const isPlayNC = ( typeof(this.moduleData.bi.image) === 'string') ? this.moduleData.bi.image.substr(0, 6) === 'plaync' : false;

    let brtag = `<br><br><br>`;
    if($('body').hasClass('ANDROID')){
        brtag = `<br><br><br><br><br><br><br>`;
    }

    return `
        <div class="ncc-left-panel">
            <div class="ncc-left-panel-wrap">
                <div class="ncc-left-panel-header">
                    <div class="ncc-lnb-header-bi">
                        <a href="${this.moduleData.bi.url}" class="ncc-bi${isPlayNC ? ' '+this.moduleData.bi.image : ''}" target="_self">
                        ${this.moduleData.bi.title}
                        </a>
                    </div>
                    <div class="ncc-lnb-header-ncservice">${cnbL10NData.Service.PlayncServices}</div>
                    <a href="#" class="ncc-left-panel-close">Close</a>
                </div>
                ${this._lnbDropdown.markUp}
                <div class="ncc-left-panel-content">
                    <div>
                        ${this._lnb.markUp}
                        ${this._appDownload.markUp}
                        ${this._serviceList.markUp}
                        ${this._banner.markUp}

                        ${brtag}
                    </div>
                </div>
            </div>
        </div>`;
  }
}

export default LeftMain;
