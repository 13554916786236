import Common from './Common';
import Dimmed from './Dimmed';

class AbsModule {
  constructor(_data, _isShow = true) {
    if (this.constructor === AbsModule) {
      throw new TypeError('Cannot construct AbsModule instances directly');
    }

    this.com = Common.instance;
    this.dim = Dimmed.instance;
    this.moduleData = _data;
    this.isShow = _isShow;
  }

  get markUp() {
    if (!this.isShow) {
      return '';
    } else {
      return this.makeMarkup();
    }
  }

  makeMarkup() {}

  start() {
    if (!this.isShow) {
      return false;
    } else {
      this.startSetting();
    }
  }

  startSetting() {}
}

export default AbsModule;
