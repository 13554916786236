import AbsModule from '../common/AbsModule';
import Shortcut from './Shortcut';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';

class UserInfo extends AbsModule {
  constructor(_config) {
    super(_config, _config.userData.isLogin);
    this._shortcut = new Shortcut(_config, _config.shortCut.isShow);
  }

  startSetting() {
    this._shortcut.start();

    jQuery('#nc-cnb .ncc-profile-links a.ncc-profile-logout')
      .off('click')
      .on('click', e => {
        let callbackFN = this.moduleData.logoutFN;
        if (typeof callbackFN === 'string' && typeof window[callbackFN] === 'function') {
          callbackFN = window[callbackFN];
        }
        if (typeof callbackFN === 'function') {
          callbackFN();
        }
        return false;
      });

    jQuery('#nc-cnb .ncc-profile-links a.ncc-profile-charchange')
      .off('click')
      .on('click', e => {
        let callbackFN = this.moduleData.charChangeFN;
        if (typeof callbackFN === 'string' && typeof window[callbackFN] === 'function') {
          callbackFN = window[callbackFN];
        }
        if (typeof callbackFN === 'function') {
          callbackFN();
          jQuery('.ncc-character-panel').addClass('is-active');
        }
        return false;
      });

    jQuery('.ncc-right-panel-close')
      .off('click')
      .on('click', () => {
        this.dim.openClose(false);
        return false;
      });
  }

  getCaseNum(accountType, state) {
    if (state) {
      return parseInt(state.case || 0, 10);
    }
    return -1;
  }

  makeMarkup() {
    /*
    1 : 프로필 이미지 아래 상태 메세지 표시
    2 ~ 6 : 프로필 이미지 나타나지 않고 그자리에 상태 메세지 표시
    7 ~  : 프로필 이미지와 숏컷 사이에 상태 메세지 표시
    */
    const accountType = this.moduleData.userData.uesAccountType || this.moduleData.userData.useAccountType;
    const ncAccount = this.moduleData.userData.ncAccount,
      gameAccount = this.moduleData.userData.gameAccount;
    let userInfo = accountType === 'gameAccount' ? gameAccount : ncAccount;
    let userName = userInfo.name !== '' ? userInfo.name : ncAccount.name;
    let profileImage = userInfo.profileImage !== '' ? userInfo.profileImage : ncAccount.profileImage;
    let userInfoTarget = userInfo.target || '_self';

    let profileEle = '';
    let errorMsgEle = '';
    let useCharChangeMenu = this.moduleData.useCharChangeMenu;
    let showChangeCharMenu = false;

    let accountState = this.moduleData.userData.accountState;
    accountState = jQuery.isArray(accountState) ? accountState : [accountState];

    // accountState 값이 없을 경우 default값 지정
    if (accountState.length === 0) {
      accountState = [{ case: 0, msg: '' }];
    }

    const charHome = this.moduleData.shortCut && this.moduleData.shortCut.charHome;

    /*
    * 1. 대표 캐릭터 미 선택
      2. 비 실명 확인
      3. 보유 캐릭터 없음
      4. L1, L2 페이스북 or 구글 로그인 / 비 실명 확인
      5. L1, L2 페이스북 or 구글 로그인 / 실명 확인
      6. 페이스북 or 구글 로그인 (L1, L2 외) / 비 실명 확인
      7. 제재 (제재_고객센터, 제재_신고센터, 제재_1:1 문의의 3개 케이스를 제재_1:1 문의로 통일)
      8. 제재-본인확인
    * */

    /*
    * 계정 상태 코드에 따른 화면 표시
      1 : 프로필 이미지 아래 상태 메세지 표시
      2 ~ 6 : 프로필 이미지 나타나지 않고 그자리에 상태 메세지 표시 (캐릭터 변경 버튼 표시안함)
      7 ~ 8 : 프로필 이미지와 숏컷 사이에 상태 메세지 표시
    * */

    // case 그룹 생성 (동일한 case를 가진 데이터 합치기)
    const caseGroup = uniqBy(accountState, data => data.case);

    if (useCharChangeMenu) {
      showChangeCharMenu = true;

      // 2 ~ 6
      const noCharacter = filter(caseGroup, obj => {
        const cNum = this.getCaseNum(accountType, obj);
        return cNum > 1 && cNum < 7;
      });

      if (noCharacter.length) {
        showChangeCharMenu = false;
      }
    }

    const profileGroup = filter(caseGroup, obj => {
      const cNum = this.getCaseNum(accountType, obj);
      return cNum < 7;
    });

    const errorMsgGroup = filter(caseGroup, obj => {
      const cNum = this.getCaseNum(accountType, obj);
      return cNum >= 7;
    });

    // 마지막 인덱스만 사용
    const profileCaseData = profileGroup.pop();
    const errorMsgCaseData = errorMsgGroup.pop();

    // 프로필 영역
    const caseData = profileCaseData || errorMsgCaseData;
    profileEle = this.getProfileEle(
      accountType,
      caseData,
      userName,
      profileImage,
      userInfo,
      userInfoTarget,
      charHome,
    );

    // 에러 메시지 영역
    if (errorMsgCaseData) {
      errorMsgEle = this.getErrorMsgEle(accountType, errorMsgCaseData);
    }

    let showNcAccount = this.moduleData.showAccountName;
    let ncAccountInfo = '',
      isNcAccount = '',
      cnbType = this.moduleData.type || '';

    if (showNcAccount && accountType === 'gameAccount' && cnbType.toLowerCase() === 'a') {
      ncAccountInfo = `<div class="nc-account-info"><p>${ncAccount.name}</p></div>`;
      isNcAccount = ' is-nc-account';
    }

    return `
        <div class="ncc-userinfo" style="background-image: url('${userInfo.profileImage}');">
            <a href="#" class="ncc-right-panel-close">Close</a>
            <div class="ncc-profile${isNcAccount}">
                <div class="ncc-profile-wrap">
                    ${profileEle}
                    <div class="ncc-profile-links">
                        ${
                          showChangeCharMenu
                            ? `<a class="ncc-profile-charchange" href="">${cnbL10NData.Loginzone.Update}</a>`
                            : ''
                        }
                        <a class="ncc-profile-logout" href="#">${cnbL10NData.Loginzone.LogOut}</a>
                    </div>
                </div>
                ${ncAccountInfo}          
            </div>
            ${this._shortcut.markUp}
        </div>
        ${errorMsgEle}
        `;
  }

  getProfileEle(accountType, state, userName, profileImage, userInfo, userInfoTarget, charHome) {
    const caseNum = this.getCaseNum(accountType, state);
    let profileEle = '';
    let homeCls = accountType === 'gameAccount' ? ' ic-home' : '';
    /*const charHomeShow = (accountType === 'gameAccount') && charHome.isShow;
    ${charHomeShow ? `<a class="ncc-char-home" href="${charHome.url}" target="${charHome.type === 'external' ? '_blank' : '_self'}" style="display:none;"></a>` : ''}*/

    if (caseNum === 0 || (caseNum >= 7 && caseNum <= 12)) {
      // 프로필 영역 기본
      profileEle = `
                    ${
                      userInfo.url
                        ? `
                    <a class="ncc-profile-img${homeCls}" href="${userInfo.url}" target="${userInfoTarget}">
                        <img src="${profileImage}" alt="${userName}" />
                    </a>
                    `
                        : `
                    <div class="ncc-profile-img">
                        <img src="${profileImage}" alt="${userName}" />
                    </div>
                    `
                    }
                    <div class="ncc-profile-info">
                        <span class="ncc-profile-info__char">${userName}</span>
                        ${accountType === 'gameAccount' ? this.subCharDataMaker(userInfo.subData) : ''}
                    </div>
                `;
    }

    let errMsgHtml = state.msg;

    if (caseNum >= 2 && caseNum <= 6) {
      // 프로필 영역 에러 메세지
      profileEle = `
                <div class="ncc-profile-error">
                    <p class="ncc-profile-error-message">${errMsgHtml}</p>
                </div>
            `;
    }

    if (caseNum === 1) {
      // 프로필 영역 캐릭터 미선택
      profileEle = `
                ${
                  userInfo.url
                    ? `
                <a class="ncc-profile-img" href="${userInfo.url}" target="${userInfoTarget}">
                    <img src="${profileImage}" alt="${userName}" />
                </a>
                `
                    : `
                <div class="ncc-profile-img">
                    <img src="${profileImage}" alt="${userName}" />
                </div>
                `
                }
                <div class="ncc-profile-info">
                    <span class="ncc-profile-info__char">${errMsgHtml}</span>
                </div>
            `;
    }

    return profileEle;
  }

  getErrorMsgEle(accountType, state) {
    const caseNum = this.getCaseNum(accountType, state);
    let errMsgHtml = state.msg;
    return caseNum >= 7 && caseNum <= 12 ? `<div class="ncc-account-message">${errMsgHtml}</div>` : '';
  }

  subCharDataMaker(_subdata) {
    const list = this.getSubData(_subdata);
    return list
      .map(
        (item, idx) => `
            <span class="ncc-profile-info__${item.title}">${item.value}</span>
        `,
      )
      .join('\n ');
  }

  getSubData(data) {
    if (typeof data === 'undefined') return [];
    let list = data.filter(it => {
      return it.value !== '' && it.value !== 'Lv';
    });
    return list;
  }
}

export default UserInfo;
