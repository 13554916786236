import AbsModule from '../common/AbsModule';

class ServiceList extends AbsModule {
  makeMarkup() {
    let addbottom = '';
    if (navigator.userAgent.indexOf('iPhone OS 15')>0) {
        addbottom = ` style="padding-bottom: 100px;"`;
    }

    return `
        <div class="ncc-ncservice"${addbottom}>
            <ul class="ncc-ncservice-list">
            
                ${
                  !this.moduleData.isServiceShow
                    ? ''
                    : `
                <li class="ncc-ncservice-item ncc-ncservice-item-plaync">
                    ${this.moduleData.service
                      .map(
                        (item, idx) => `
                            <a href="${item.url}" class="${item.className}"><span>${item.title}</span></a>
                         `,
                      )
                      .join('\n ')}
                </li>
                `
                }
                
                ${
                  !this.moduleData.isPcShow
                    ? ''
                    : `
                <li class="ncc-ncservice-item ncc-ncservice-item-game">
                    <div class="ncc-ncservice-item-game-title">${cnbL10NData.Service.PCGames}</div>
                    <ul>
                    ${this.moduleData.pc
                      .map(
                        (item, idx) => `
                            <li class="">
                                <a href="${item.url}" class="${item.className}"><span>${item.title}</span></a>
                            </li>
                        `,
                      )
                      .join('\n ')}
                    </ul>
                </li>
                `
                }
                
                ${
                  !this.moduleData.isMobileShow
                    ? ''
                    : `
                <li class="ncc-ncservice-item ncc-ncservice-item-game">
                    <div class="ncc-ncservice-item-game-title">${cnbL10NData.Service.MobileGames}</div>
                    <ul>
                    ${this.moduleData.mobile
                      .map(
                        (item, idx) => `
                            <li class="">
                                <a href="${item.url}" class="${item.className}"><span>${item.title}</span></a>
                            </li>
                        `,
                      )
                      .join('\n ')}
                    </ul>
                </li>
                `
                }
                
                ${
                  !this.moduleData.isMobileServiceShow
                    ? ''
                    : `
                <li class="ncc-ncservice-item ncc-ncservice-item-game">
                    <div class="ncc-ncservice-item-game-title">${cnbL10NData.Service.MobileServices}</div>
                    <ul>
                      ${this.moduleData.mobileService
                        .map(item => {
                          return `
                          <li>
                            <a href="${item.url}" class="${item.className}"><span>${item.title}</span></a>
                          </li>
                          `;
                        })
                        .join('\n ')}
                    </ul>
                </li>
                `
                }                
            </ul>
        </div>
        `;
  }
}

export default ServiceList;
