import AbsModule from '../common/AbsModule';
import Login from './Login';
import GameStart from './GameStart';
import Search from './Search';
import Lnb from './Lnb';
import LnbDropdown from '../components/LnbDropdown';
import Swipe from '../common/SwipeMove';
import isEmpty from 'lodash/isEmpty';

class HeaderMain extends AbsModule {
  constructor(_config) {
    super(_config);

    jQuery('body').toggleClass('cnb-no-hamburger', !this.moduleData.useLeftMenu);

    this.setMenuActive();

    this._con = '#nc-cnb .ncc-header ';
    this._login = new Login(_config, _config.useLoginMenu);
    this._gameStart = new GameStart(_config, !isEmpty(_config.useGameStart) && _config.useGameStart !== '');
    this._search = new Search(_config, !isEmpty(_config.search) && _config.search.isShow);
    this._lnbDropdown = new LnbDropdown('header', _config, !isEmpty(_config.lnbDropdown));
    this._lnb = new Lnb(_config);
  }

  escapeSpecialChars(string) {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }

  setMenuActive() {
    const lnb = this.moduleData.lnbData;
    const activeMenu = [];
    let hasRegexp = false;

    if (typeof this.moduleData.active === 'undefined' && jQuery.isArray(lnb)) {
      const mdata = { d1: 0, d2: 0 };
      const href = window.location.href;
      const locPathName = window.location.pathname;

      let i = 0,
        j = 0,
        menu,
        sub,
        urls,
        subUrls,
        isMatch;

      for (; i < lnb.length; ++i) {
        menu = lnb[i];

        if (jQuery.isArray(menu.sub)) {
          for (j = 0; j < menu.sub.length; ++j) {
            sub = menu.sub[j];

            if (sub.regex && sub.regex.pattern) {
              const { pattern, flags } = sub.regex;
              isMatch = new RegExp(pattern, flags).test(locPathName);

              if (isMatch) {
                mdata.d1 = i + 1;
                mdata.d2 = j + 1;
                hasRegexp = true;
                break;
              }
            } else {
              subUrls = [sub.url].concat(sub.otherUrls || []);
              isMatch = subUrls.some(url => {
                return new RegExp(this.escapeSpecialChars(url), 'gi').test(href);
              });

              if (isMatch) {
                mdata.d1 = i + 1;
                mdata.d2 = j + 1;
              }
            }
          }
        }

        // regex
        if (menu.regex && menu.regex.pattern) {
          const { pattern, flags } = menu.regex;
          isMatch = new RegExp(pattern, flags).test(locPathName);
          if (isMatch) {
            mdata.d1 = i + 1;
            hasRegexp = true;
            break;
          }
        } else {
          let matchUrl;
          urls = [menu.url].concat(menu.otherUrls || []);

          isMatch = urls.some(url => {
            if (url === '' || url === '/') {
              return false;
            }

            // 도메인만 존재하는 경로라면 예외처리
            const match = url.match(/:\/\/(?:www\.)?(.[^/]+)(.*)/);
            if (match && match.length > 2) {
              const pathname = match[2];
              if (pathname === '' || pathname === '/') {
                return false;
              }
            }

            const m = new RegExp(this.escapeSpecialChars(url), 'gi').test(href);
            if (m) {
              matchUrl = url;
            }
            return m;
          });

          if (isMatch) {
            mdata.d1 = i + 1;
            activeMenu.push({ index: i + 1, matchUrl });
          }
        }
      }

      if (!hasRegexp && activeMenu.length > 1) {
        // 중복 체크가 되었을 경우 가장 긴 url 네임을 가진 인덱스를 활성화 한다.
        const activeMenuTarget = activeMenu.reduce((acc, cur) => {
          const url1 = acc.matchUrl;
          const url2 = cur.matchUrl;
          return url1.length > url2.length ? acc : cur;
        });

        mdata.d1 = activeMenuTarget.index;
      }

      this.moduleData.active = mdata;
    }
  }

  startSetting() {
    this._login.start();
    this._gameStart.start();
    this._search.start();
    this._lnbDropdown.start();
    this._lnb.start();

    if (!this._lnb.disabled && jQuery('#ncc-depth2-tap').length) {
      let depth =
        this.moduleData.type === 'C' && this.moduleData.useMobileNav
          ? this.moduleData.active.d1
          : this.moduleData.active.d2;
      new Swipe('#ncc-depth2-tap', '.ncc-depth2-list > .ncc-depth2-list-items', depth);
    }

    jQuery(this._con + '.ncc-gnb-wrap .ncc-ncservice-btn').on('click', e => {
      this.dim.openClose(true, 'left');
      e.preventDefault();
    });

    jQuery(this._con + '.ncc-lnb-wrap .ncc-account-message-close').on('click', e => {
      jQuery(this._con + '.ncc-lnb-wrap .ncc-account-message').hide();
      return false;
    });

    let $window = jQuery(window);
    let mobileScreenSize = 960;
    let isMobileScreenSize = $window.outerWidth() < mobileScreenSize;

    let onResize = () => {
      if ($window.outerWidth() >= mobileScreenSize) {
        $window.off('resize.lnb');
        this._lnb.hoverBarMove(this.moduleData.active.d1, this.moduleData.active.d2);
      }
    };

    let elCNB = jQuery('#nc-cnb');
    let elHeader = jQuery('.ncc-header');
    let nCnbPosY = elHeader.height();
    let posY = nCnbPosY;
    let $winH = jQuery(window).height();

    let onScroll = () => {
      // 전체화면일 경우 리턴
      let $docH = jQuery(document).height();
      if ($docH === $winH) {
        elCNB.removeClass('is-sticky');
        return;
      }

      // body 상단에 배너가 붙었을 경우 대비
      let offsetTop = (elCNB.offset() && elCNB.offset().top) || 0;
      posY = nCnbPosY + offsetTop;

      let scrTop = $window.scrollTop();
      let bodyIsFixed = jQuery('body').css('position') === 'fixed';
      let bodyMarginTop = Math.abs(
        jQuery('body')
          .css('margin-top')
          .replace('px', ''),
      );
      let isSticky = false;
      let isTop = false;

      if (scrTop >= offsetTop) isTop = true;
      if (scrTop >= posY) isSticky = true;
      if (bodyIsFixed && bodyMarginTop >= posY) isSticky = true;
      elCNB.toggleClass('is-sticky', isSticky);
      elCNB.toggleClass('is-top', isTop);
    };

    $window.scroll(onScroll);
    onScroll();

    if (isMobileScreenSize) {
      $window.on('resize.lnb', onResize);
      onResize();
    }

    if (this.com.__isMobile) {
      function mobileScr() {
        let scrTop = $window.scrollTop();
        elHeader.toggleClass('fixed', scrTop > 0);
      }

      $window.on('scroll touchmove touchstart touchend touchcancel touchforcechange click', mobileScr);
      mobileScr();
    }
  }

  makeMarkup() {
    if (this.moduleData.useGameStart === 'false') {
      this.moduleData.useGameStart = false;
    }

    let showSearch = this.moduleData.search ? this.moduleData.search.isShow : false;
    let useGameStart = this.moduleData.useGameStart ? this.moduleData.useGameStart : false;
    let lnbWrap =
      !useGameStart && this._lnb.disabled && !showSearch
        ? ''
        : `
        <div class="ncc-lnb-wrap">
            ${this._gameStart.markUp}
            ${this._lnbDropdown.markUp}
            ${this._lnb.markUp}
        </div>
        `;
    let childNavCls = lnbWrap === '' ? '' : ' is-child-nav';
    let subMrkup = this.makeSubTitleMarkup();
    let is2Depth = subMrkup.indexOf('ncc-depth2-tap') > -1;
    childNavCls = is2Depth ? childNavCls + ' is-2depth' : childNavCls;

    return `
        <div class="ncc-header${childNavCls}">
            <div class="ncc-gnb-wrap">
                <div class="ncc-gnb-wrap__bg"></div>
                <a class="ncc-ncservice-btn" href="#">Open<span><i></i><i></i><i></i></span></a>
                ${subMrkup}
                ${this._search.markUp}
                ${this._login.markUp}
            </div>
            ${lnbWrap}
        </div>
        `;
  }

  makeSubTitleBiMarkup(isHide = false) {
    const { bi = {} } = this.moduleData;
    const isLogoUrl = typeof bi.logo !== 'undefined' && bi.logo !== '';
    const logoTypePlayNC = ( typeof(bi.image) === 'string') ? bi.image.substr(0, 6) === 'plaync' : false;

    // logo url을 사용한다면 디폴트 타입: all / 아니면 image
    const defaultBIType = isLogoUrl ? 'all' : 'image';

    let biType = bi.type || defaultBIType;

    const biCls = logoTypePlayNC ? ' '+ bi.image : '';

    const biProps = `class="ncc-bi${biCls}" data-type="${biType}" ${isHide ? 'style="display:none;"' : ''}`;
    let markup = `<a ${biProps} href="${bi.url}" target="_self"><span>${bi.title}</span></a>`;

    // image + text
    if (biType === 'all') {
      markup = `<div ${biProps}>
                  <a href="${bi.logo || bi.url}" class="logo" target="_self"></a>
                  <a href="${bi.url}" class="bi-text" target="_self">${bi.title}</a>
                </div>`;
    }

    return markup;
  }

  makeSubTitleMarkup() {
    const { d1 = 0, d2 = 0 } = this.moduleData.active;
    let lnbDataD1 = !this._lnb.disabled ? this.moduleData.lnbData[d1 - 1] : false;

    // Type C
    if (this.moduleData.type === 'C' && this.moduleData.lnbData && this.moduleData.lnbData.length) {
      if (this.moduleData.useMobileNav) {
        // C타입에서 1뎁스 메뉴 모바일 해상도에서도 고정 노출
        const subArr = this.getSubMenuMarkup(this.moduleData.lnbData, d1);
        const isActive = typeof d1 !== 'undefined' && d1 > 0;

        return `${this.makeSubTitleBiMarkup()}
              <nav class="ncc-lnb-title ncc-depth1-menu">
                  <div class="ncc-depth2-list-wrap" id="ncc-depth2-tap">
                      <div class="ncc-depth2-scroller">
                          <div class="ncc-depth2-list ${isActive ? 'active' : ''}">
                          ${subArr}
                          </div>
                      </div>
                  </div>
              </nav>
              `;
      } else {
        return `${this.makeSubTitleBiMarkup()}`;
      }
    }

    // not Type C
    if (d1 === 0 || !lnbDataD1) {
      return `${this.makeSubTitleBiMarkup()}`;
    } else {
      let subArr = this.moduleData.lnbData[d1 - 1].sub;
      let lnbDataD2 = subArr && subArr[d2 - 1];

      const getTitleLink = linkData => {
        return `<a href="${linkData.url}">${linkData.title}</a>`;
      };

      if (d2 === 0 || !lnbDataD2) {
        return `${this.makeSubTitleBiMarkup(this.moduleData.type !== 'C' && this.moduleData.type !== 'D')}
                <nav class="ncc-lnb-title">
                    <p class="ncc-lnb-title--current is-empty-sibling">${getTitleLink(
                      this.moduleData.lnbData[d1 - 1],
                    )}</p>
                </nav>`;
      } else {
        return `${this.makeSubTitleBiMarkup(true)}
                <nav class="ncc-lnb-title">
                    <p class="ncc-lnb-title--current">${getTitleLink(this.moduleData.lnbData[d1 - 1])}</p>
                    <div class="ncc-depth2-list-wrap" id="ncc-depth2-tap">
                        <div class="ncc-depth2-scroller">
                            <div class="ncc-depth2-list">
                            ${this.getSubMenuMarkup(this.moduleData.lnbData[d1 - 1].sub, d2)}
                            </div>
                        </div>
                    </div>
                </nav>`;
      }
    }
  }

  getSubMenuMarkup(sub, selectedIndex) {
    return sub
      .map((item, idx) => {
        let selectedCls = idx + 1 === selectedIndex ? ' selected' : '';
        return `
            <span class="ncc-depth2-list-items s${idx + 1}${selectedCls}">
                <a ${
                  item.iconType == 'popup'
                    ? ` href="javascript:void(0)" onclick="window.open('${item.url}', '${item.popName}', '${item.popOption}').focus();return false;"`
                    : `href="${item.url}"`
                }
                   ${item.iconType == 'external' ? ` target="_blank"` : ''}>
                   ${item.title}
                   ${item.iconType == 'new' ? `<em class="icon-new">N</em>` : ''}
                   ${item.iconType == 'external' ? `<em class="icon-external">external</em>` : ''}
                   ${item.iconType == 'popup' ? `<em class="icon-external">popup</em>` : ''}
                </a>
            </span>`;
      })
      .join('\n ');
  }
}

export default HeaderMain;
