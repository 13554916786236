import AbsModule from '../common/AbsModule';
import { CNB_TYPE } from '../common/Common';

class Login extends AbsModule {
  startSetting() {
    jQuery(
      '#nc-cnb .ncc-login--info a, #nc-cnb .ncc-login--mobile .ncc-login--mobile-btn, #nc-cnb .ncc-login--user-btn',
    )
      .off('click')
      .on('click', e => {
        this.dim.openClose(true, 'right');

        if (typeof window.ncNotice === 'object') {
          window.ncNotice.start();
        }
        // return false;
        e.preventDefault();
      });

    jQuery('#nc-cnb .ncc-login--before .ncc-login__link-login')
      .off('click')
      .on('click', e => {
        let callbackFN = this.moduleData.loginFN;
        if (typeof callbackFN === 'string' && typeof window[callbackFN] === 'function') {
          callbackFN = window[callbackFN];
        }
        if (typeof callbackFN === 'function') {
          callbackFN();
        }
        // return false;
        e.preventDefault();
      });

    jQuery('#nc-cnb .ncc-login-simple--after .ncc-logout__link-logout')
      .off('click')
      .on('click', e => {
        let callbackFN = this.moduleData.logoutFN;
        if (typeof callbackFN === 'string' && typeof window[callbackFN] === 'function') {
          callbackFN = window[callbackFN];
        }
        if (typeof callbackFN === 'function') {
          callbackFN();
        }
        // return false;
        e.preventDefault();
      });
  }

  makeMarkup() {
    let joinURL = this.moduleData.joinURL;
    if (window.cnbLoginData && window.cnbLoginData.signup) {
      joinURL = window.cnbLoginData.signup;
    }

    let htmlstr = '';
    if (this.moduleData.userData.isLogin) {
      htmlstr = this.loginAfter(this.moduleData.userData);
    } else {
      htmlstr = `
                <div class="ncc-login--before">
                    <a href="" class="ncc-login__link ncc-login__link-login">${cnbL10NData.Header.login}</a>
                    ${
                      this.moduleData.useJoinMenu
                        ? `<a href="${joinURL}" class="ncc-login__link ncc-login__link-join"> ${cnbL10NData.Header.join}</a>`
                        : ''
                    }
                </div>
            `;
    }

    return `
		<div class="ncc-login">
		    ${htmlstr}
		</div>`;
  }

  loginAfter(_userData) {
    /*
        1 : 프로필 이미지 + 상태 메시지
        2 ~ 6 : 프로필 이미지 + ncAccount 닉네임
        7 ~  : 프로필 이미지 + gameAccount 닉네임 + 서버정보
        */
    const accountType = _userData.uesAccountType || _userData.useAccountType;
    const ncAccount = _userData.ncAccount,
      gameAccount = _userData.gameAccount;

    let accountState = this.moduleData.userData.accountState;
    accountState = jQuery.isArray(accountState) ? accountState[0] || {} : accountState;

    let caseNum = parseInt(accountState.case || 0, 10);
    if (accountType === 'ncAccount' && caseNum > 0 && caseNum < 3) {
      caseNum += 8;
    }

    let userInfo = accountType === 'gameAccount' ? gameAccount : ncAccount;
    let userName = userInfo.name !== '' ? userInfo.name : ncAccount.name;
    let profileImage = userInfo.profileImage !== '' ? userInfo.profileImage : ncAccount.profileImage;

    const serverInfo = this.getSubData(userInfo.subData);
    const showServerInfo = accountType === 'gameAccount' && (caseNum < 1 || caseNum > 6) && serverInfo !== '';
    const useUserInfo =
      typeof this.moduleData.useUserInfo === 'undefined' ? true : this.moduleData.useUserInfo;
    const cnbType = useUserInfo ? this.moduleData.type : 'B';

    let accountStateMsg = accountState.msg;
    if (accountStateMsg) {
      accountStateMsg = accountStateMsg.replace(/<br\s*[\/]?>/gi, '&nbsp;');
    }

    let loginAfterMarkup = '';

    switch (cnbType) {
      /*case CNB_TYPE.B:
                loginAfterMarkup = `
                <div class="ncc-login-simple--after">
                    <a href="" class="ncc-logout__link ncc-logout__link-logout">${cnbL10NData.Loginzone.LogOut}</a>
                </div>
                `;
                break;
            case CNB_TYPE.A :
            case CNB_TYPE.C :
            case CNB_TYPE.D :*/
      default:
        loginAfterMarkup = `
                <div class="ncc-login--after">
                    <div class="ncc-login--mobile">
                        <button class="ncc-login--mobile-btn">
                            <img src="${profileImage}" alt="" class="ncc-login--info__thumb">
                        </button>
                        <span class="ncc-login--info__noti">
                            <span class="is-on">0</span>
                        </span>
                    </div>
                    <div class="ncc-login--info">
                        <a href="#">
                            <img src="${profileImage}" alt="" class="ncc-login--info__thumb" />
                            <span class="ncc-login--info__char">
                            ${caseNum === 1 ? accountStateMsg : userName}
                            </span>
                            ${
                              showServerInfo
                                ? `<span class="ncc-login--info__server">${serverInfo}</span>`
                                : ''
                            }
                            ${this.notiFriendCount()}
                        </a>
                    </div>
                </div>
                `;
    }

    return loginAfterMarkup;
  }

  getSubData(data) {
    if (typeof data === 'undefined') return '';
    let list = data.filter(it => {
      return it.value !== '' && it.value !== 'Lv';
    });
    return list.map(it => it.value).join(', ');
  }

  loginStatus(statusData) {
    return `서비스 이용을 위해 본인확인이 필요합니다. <a href="#">${cnbL10NData.Common.IDVerification}</a>`;
  }

  notiFriendCount() {
    return `
		<span class="ncc-login--info__noti">${cnbL10NData.Common.Notifications} <span class="is-on">0</span></span>
		`;
  }
}

export default Login;
