import AbsModule from '../common/AbsModule';
import * as Util from '../common/util';

class Shortcut extends AbsModule {
  constructor(_config) {
    super(_config.shortCut, _config.shortCut.isShow);
    this.dataBindList = [];
  }

  startSetting() {
    let shortcutUL = jQuery('#nc-cnb .ncc-shortcut-list');

    if (Util.isMobile) {
      jQuery('#nc-cnb .ncc-shortcut-item__link__mypage')
        .off('click')
        .on('click', e => {
          let url = jQuery(e.currentTarget).attr('href');
          window.open(url, 'myPage');
          return false;
        });

      shortcutUL.css({ 'overflow-x': 'auto' });
    } else {
      let shortListWidth = jQuery('#nc-cnb .ncc-shortcut-list .ncc-shortcut-item').length * 70;

      if (shortListWidth > 350) {
        jQuery('#nc-cnb .ncc-shortcut-btn--prev')
          .off('click')
          .on('click', e => {
            let nowScrLeft = shortcutUL.scrollLeft();
            shortcutUL.animate({ scrollLeft: nowScrLeft - 210 }, 250);
          });

        jQuery('#nc-cnb .ncc-shortcut-btn--next')
          .off('click')
          .on('click', e => {
            let nowScrLeft = shortcutUL.scrollLeft();
            shortcutUL.animate({ scrollLeft: nowScrLeft + 210 }, 250);
          });

        jQuery(window).on('resize.shortcut', e =>
          jQuery('#nc-cnb .ncc-shortcut-btn').toggle(shortListWidth > shortcutUL.width()),
        );
        jQuery(window).trigger('resize.shortcut');
      }
    }

    // function 타입 숏컷
    jQuery('.ncc-shortcut-item__link[data-func-name]').click(function() {
      const funcName = jQuery(this).data('func-name');
      const funcParams = jQuery(this).data('func-params');

      let $this = this;

      const func = funcName.split('.').reduce((acc, curVal) => {
        const obj = acc[curVal];
        if (typeof obj === 'object') {
          $this = obj;
        }
        return obj;
      }, window);

      if (jQuery.isFunction(func)) {
        func.call($this, funcParams);
      }
      return false;
    });
  }

  makeMarkup() {
    let listLeng = 0;
    let shortCutList = jQuery.extend(true, {}, this.moduleData);
    if (shortCutList.charHome) shortCutList.charHome = {};

    for (let prop in shortCutList) {
      let item = shortCutList[prop];
      if (item.isShow) listLeng++;
    }

    const nshopData = window.userData.nshop || window.userData.nShop || {};
    let nCoin = cnbL10NData.Shortcut.NCoin;
    let EnterCoupon = cnbL10NData.Shortcut.EnterCoupon;

    let nCoinItem = this.getShortCutItem('ncoin', nCoin, nshopData.nCoin || nshopData.ncoin, 999999, '{}');
    let enterCouponItem = this.getShortCutItem('entercoupon', EnterCoupon, nshopData.coupon, 99, '쿠폰 {}개');

    return `
        <nav class="ncc-shortcut">
            <strong class="blind">${cnbL10NData.Shortcut.GotoService}</strong>
            <ul id="ncc-shortcut-list" class="ncc-shortcut-list ncc-shortcut-list-length${listLeng}">
                ${this.makeItem(shortCutList.charHome, 'home', cnbL10NData.Shortcut.CharacterHome)}
                ${this.makeItem(shortCutList.mypage, 'mypage', cnbL10NData.Shortcut.MyPage)}
                ${this.makeItem(shortCutList.mynshop, 'mynshop', cnbL10NData.Shortcut.MyNShop)}
                ${this.makeItem(shortCutList.ncoin, nCoinItem.iconClass, nCoinItem.value, true)}
                ${this.makeItem(shortCutList.enterCoupon, enterCouponItem.iconClass, enterCouponItem.value)}
                ${this.makeItem(shortCutList.coupon, 'coupon', cnbL10NData.Shortcut.Coupon, true)}
                ${this.makeItem(shortCutList.couponGame, 'coupon', cnbL10NData.Shortcut.Coupon, true, 'game')}
                ${this.makeItem(shortCutList.npoint, 'npoint', cnbL10NData.Shortcut.NPoint, true)}
                ${this.makeItem(shortCutList.npointGame, 'npoint', cnbL10NData.Shortcut.NPoint, true, 'game')}
                ${Util.isMobile ? '' : this.makeItem(shortCutList.gift, 'gift', cnbL10NData.Shortcut.Gift)}
                ${this.makeItem(shortCutList.ticket, 'ticket', cnbL10NData.Shortcut.Ticket)}
                ${this.makeItem(shortCutList.security, 'security', cnbL10NData.Shortcut.Security)}
                ${this.makeItem(shortCutList.cs, 'cs', cnbL10NData.Shortcut.Inquiry)}
            </ul>
            <button type="button" class="ncc-shortcut-btn ncc-shortcut-btn--prev" style="display:none;">&lt;</button>
            <button type="button" class="ncc-shortcut-btn ncc-shortcut-btn--next" style="display:none;">&gt;</button>
        </nav>`;
  }

  getShortCutItem(name, l10n, count, maxCount, _prefix) {
    let iconClass = name,
      value = l10n;
    if (count) {
      value = this.getShortCutNumber(count, maxCount);
      if (value === '' || parseInt(value, 10) === 0) {
        value = l10n;
      } else {
        iconClass += ' is-number';
        value = _prefix.replace('{}', value);
      }
    }

    return { iconClass, value };
  }

  getShortCutNumber(num, maxCount) {
    if (jQuery.isNumeric(num)) {
      num = num
        .toString()
        .replace(/^\s\s*/, '')
        .replace(/\s\s*$/, '');
      let chkNum = parseInt(num, 10),
        isFull = '';
      if (chkNum > maxCount) {
        num = maxCount.toString();
        isFull = '+';
      }
      return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + isFull;
    } else {
      return '';
    }
  }

  makeItem(_d, _className, _l10nName, _addData = false, _suffix = '') {
    if (typeof _d === 'undefined') return '';
    if (!_d.isShow) return '';
    if (_addData) this.dataBindList.push(_className + (_suffix ? '-' + _suffix : ''));

    // console.log(_d);
    const type = _d.iconType || _d.type;

    const link = `<a href="${_d.url}" ${
      type === 'external' ? 'target="_blank"' : 'target="_self"'
    } class="ncc-shortcut-item__link">`;
    const popup = `<a href="javascript:void(0)" onclick="window.open('${_d.url}', '${_d.popupName}', '${_d.popOption}').focus(); return false;" class="ncc-shortcut-item__link">`;

    const funcParamsStr = JSON.stringify(_d.params);
    const button = `<a href="#" class="ncc-shortcut-item__link" data-func-name="${_d.name}" data-func-params='${funcParamsStr}' >`;

    let aTag = '';
    switch (type) {
      case 'popup':
        aTag = popup;
        break;
      case 'function':
        aTag = button;
        break;
      default:
        aTag = link;
    }

    return `
        <li class="ncc-shortcut-item">
            ${aTag}
                <i class="icon-shortcut icon-shortcut--${_className}"></i>
                <span class="desc" id="ncc-shortcut-databind-${_className}${
      _suffix ? '-' + _suffix : ''
    }">${_l10nName}</span>
            </a>
        </li>`;
  }

  /*createPopup(url, params) {
        let paramsStr = '';
        let keys = Object.keys(params);
        if (!keys.length) return '';
        let i, len = keys.length;
        for (i = 0; i < len; i++) {
            let key = keys[i];
            paramsStr += key + '=' + params[key];
            if(i < len-1) {
                paramsStr += ', ';
            }
        }
        return `window.open('${url}', '', '${paramsStr}').focus(); return false;`;
    }*/
}

export default Shortcut;
