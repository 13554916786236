var isApp;
var isRc = '';
var isMobile;

function init() {
  isApp = window.npConf && window.npConf.isApp == 'true';
  isRc = /(rc.)/i.test(location.href) ? 'rc.' : '';

  isMobile = /(android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|NC Noob)/i.test(
    navigator.userAgent.toLowerCase(),
  );
}

//링크 주소 펙토리-------------------------------------------------------------------------------------------
function factory(_obj) {
  /*
   * 대상 알림코드 : 1000XXXXXX (뒷자리 6자를 제외하면 1000 이 되는 모든 알림 코드입니다. 1000001001, 1000001002, 1000002001, ......)
   *	안내 메시지 : 퍼플에서 확인해 주세요.
   * */
  var notiCode = _obj.notiCode.toString();
  var isPurpleNotiCode = notiCode.substr(0, 4) === '1000';

  var link = '';
  var thisSite = '';
  if (window.npConf) {
    thisSite = npConf.site;
  }
  if (jQuery('#nc-cnb').length > 0 && window.userData) {
    thisSite = userData.site;
  }

  if (_obj.msgLink) {
    var thisDeviceLink;
    if (isApp) {
      thisDeviceLink = _obj.msgLink.mobileApp || _obj.msgLink.mobile;
    } else if (isMobile) {
      thisDeviceLink = _obj.msgLink.mobile;
    } else {
      thisDeviceLink = _obj.msgLink.web;
    }

    if (!!thisDeviceLink) {
      // 앱일경우 캐릭터 정보를 붙여서 보낸다
      if (isApp) {
        thisDeviceLink = addParam(
          thisDeviceLink,
          'characterName',
          encodeURI(_obj.subscriber.characterName) || '',
        );
        thisDeviceLink = addParam(thisDeviceLink, 'serverId', _obj.subscriber.serverID || '');
      }

      // 주소에 redirect 문자열이 있을 경우 site=bns 형식의 파람을 붙인다
      if (thisDeviceLink.indexOf('redirect') > 0) {
        thisDeviceLink = addParam(thisDeviceLink, 'site', thisSite);
      }

      // 친구 수락 예외 처리
      if (
        String(_obj.notiCode).substring(0, 5) == '23002' ||
        String(_obj.notiCode).substring(0, 5) == '23001' ||
        String(_obj.notiCode).substring(0, 5) == '23003'
      ) {
        var arr = thisDeviceLink.split('?');
        if (arr[1]) {
          thisDeviceLink = arr[0] + '/' + thisSite + '/timeline/wall?' + arr[1];
        }
      }

      link = 'href="' + thisDeviceLink + '"';
    } else {
      var txt = notiL10NData.availableCheck(
        _obj.msgLink.ingame,
        _obj.msgLink.mobileApp,
        _obj.msgLink.mobile,
        _obj.msgLink.web,
      );
      link = 'onclick="alert(' + txt + ');"';
      if (!_obj.msgLink.ingame && !_obj.msgLink.mobileApp && !_obj.msgLink.mobile && !_obj.msgLink.web)
        link = '';
    }
  }

  if (isPurpleNotiCode) {
    var msg = notiL10NData.AvailablePurple;
    link = 'onclick="window.cnb.message(\'' + msg + '\')"';
  }

  return link;
}

function addParam(_link, _name, _value) {
  var connect = _link.indexOf('?') == -1 ? '?' : '&';
  return _link + connect + _name + '=' + _value;
}

function replaceURL(_url) {
  if (!window.isTestServer || !isTestServer()) return _url;
  return 'http://' + isRc + 'tg.' + _url.substr(_url.indexOf('sandbox.plaync.com'));
}

//초기 실행 -------------------------------------------------------------------------------------------
jQuery(function() {
  init();
});

const _link = {
  factory: factory,
};

export default _link;
