export default function(strings, ...keys) {
  return function(data) {
    let temp = strings.slice();
    keys.forEach((key, i) => {
      // key="value" 형태로 사용한다.
      const attrs = key.split('.');
      if (attrs.length > 1) {
        key = attrs[1];
        const value = data[key];

        if (value && value !== '') {
          temp[i] = temp[i] + attrs[0] + '="' + data[key] + '"';
        }
      } else {
        temp[i] = temp[i] + data[key];
      }
    });
    return temp.join('');
  };
}
