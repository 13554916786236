import AbsModule from '../common/AbsModule';
import assign from 'lodash/assign';

class Banner extends AbsModule {
  startSetting() {}

  makeMarkup() {
    let list = [];
    for (let prop in this.moduleData) {
      if (prop !== 'isShow') {
        list.push(assign(this.moduleData[prop], { name: prop }));
      }
    }

    const baners_markup = list
      .map(o => {
        let imgPath = this.com.__staticHost + o.imgPath;
        // let style = `background-image: url(${imgPath})`;

        return `
         <div class="banner-item" data-name="${o.name}">
            <a href="${o.link}" target="${o.target}">
              <img src="${imgPath}" alt="banner">
            </a>
         </div>
      `;
      })
      .join('');

    return `
       <div class="ncc-banner">
         ${baners_markup}      
       </div>  
        `;
  }
}

export default Banner;
