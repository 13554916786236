import tmpl from './notiTemplate';

var api = {},
  isRc = '',
  isApp = false,
  isLoading = false,
  container = {},
  nowNode = {},
  puid = '',
  prid = '';

function init() {
  isRc = /(rc.)/i.test(location.href) ? 'rc.' : '';
  isApp = window.npConf && window.npConf.isApp == 'true';

  var nowDomain = /(asia\.nc\.com)/i.test(location.href) ? 'asia.nc.com' : 'noti.plaync.com';

  api = {
    deleteNoti: 'http://' + isRc + nowDomain + '/noti/api/common/delete.jsonp', //알림삭제
    accept: 'http://' + isRc + 'sandbox.plaync.com/$!/my/friend/propose/accept.jsonp', //친구수락
    FriendAgree: 'http://' + isRc + 'sandbox.plaync.com/$!/my/profile/updateAgreeFriendAccept.jsonp', //약관동의
    hide: function(_puid, _prid) {
      //친구신청숨기기
      var prefix = 'http://' + isRc + 'sandbox.plaync.com';
      return prefix + '/$!/bns/friend/' + _puid + '/' + _prid + '/propose/hide.jsonp';
    },
  };

  container = jQuery('#notiList ul');

  //수락 버튼 클릭
  jQuery('#nc-cnb').on('click', '#notiList .wrapButton .btAllow', function(e) {
    // console.log(nowNode, this);
    nowNode = jQuery(this)
      .parents('li')
      .eq(0);
    puid = jQuery(nowNode).data().publisher.id;
    prid = jQuery(nowNode).data().proposalId;

    friendAccept();
  });

  //숨기기 버튼 클릭
  jQuery('#nc-cnb').on('click', '#notiList .wrapButton .btHidden', function(e) {
    nowNode = jQuery(this)
      .parents('li')
      .eq(0);
    puid = jQuery(nowNode).data().publisher.id;
    prid = jQuery(nowNode).data().proposalId;

    jQuery.ajax({
      url: api.hide(puid, prid),
      dataType: 'jsonp',
      type: 'GET',
      data: '',
      success: function(_data) {
        jQuery(nowNode).hide();
        if (_data.result == 'SUCCESS') {
        } else {
        }
      },
      error: function() {
        alertOrModal(notiL10NData.ErrorOccurred); //알림 삭제 실패
      },
      beforeSend: function() {},
      complete: function() {},
    });

    jQuery.ajax({
      url: api.deleteNoti,
      dataType: 'jsonp',
      type: 'GET',
      data: { notiID: jQuery(nowNode).data().id, targetCategory: 'GNB_NOTI' },
      success: function(_data) {
        jQuery(nowNode).hide();
        if (_data.result.returnMessage == 'SUCCESS') {
        } else {
        }
      },
      error: function() {
        alertOrModal(notiL10NData.ErrorOccurred); //알림 삭제 실패
      },
      beforeSend: function() {},
      complete: function() {},
    });
  });
}

//친구수락 요청
function friendAccept() {
  if (isLoading) return;
  jQuery.ajax({
    url: api.accept,
    data: { friendId: puid, proposalId: prid },
    dataType: 'jsonp',
    type: 'GET',
    success: friendAcceptComp,
    beforeSend: function() {
      isLoading = true;
    },
    complete: function() {
      isLoading = false;
    },
    error: function() {},
  });
}

//서버응답
function friendAcceptComp(_data) {
  if (_data.result == 'SUCCESS' || _data.result == '4006') {
    jQuery(nowNode).empty();

    var okmsg = tmpl.tmpOkFriend(jQuery(nowNode).data());
    jQuery(nowNode).append(okmsg);
    alert(notiL10NData.FriendRequest);
  } else if (_data.result == 'NO_AGREEMENT_FRIEND_ACCEPT') {
    friendAgreePop();
  } else if (_data.result == '4015' || _data.result == '4016') {
    alertOrModal(notiL10NData.UnableAddFriends);
  } else if (_data.result == '4000') {
    alertOrModal(notiL10NData.InformationNotAvailable);
  } else {
    alertOrModal(notiL10NData.ErrorOccurred); //친구 수락 실패
  }
}

//NC 친구 사용 동의 팝업
function friendAgreePop(_testNick) {
  var nick = !_testNick ? jQuery(nowNode).data().publisher.nickname : _testNick;

  if (jQuery('#friendAgree_M').length) {
    jQuery($('#friendAgree_M')).modal({ click: false, keyboard: false });
  } else {
    $(tmpl.friendAgree_M(nick)).modal({ click: false, keyboard: false });
  }

  //팝업: 동의
  $('#friendAgree_M').on('click', '.yes', function(e) {
    $('#friendAgree_M').modal('hide');
    $('body').css('overflow', 'auto');
    jQuery.ajax({ url: api.FriendAgree, success: friendAgreeComp, dataType: 'jsonp', type: 'GET' });
  });

  //팝업: 거부
  $('#friendAgree_M').on('click', '.no', function(e) {
    $('#friendAgree_M').modal('hide');
    $('body').css('overflow', 'auto');
  });

  //팝업: 닫기
  $('#friendAgree_M').on('click', '.js_close', function(e) {
    $('#friendAgree_M').modal('hide');
    $('body').css('overflow', 'auto');
  });

  //메신저 설치하기
  $('#friendAgree_M').on('click', '.btnNcMsn', function(e) {
    if (isApp) {
      location.href = 'ncm://message?guid=' + puid;
    } else {
      AppLink.excute('ncm://message?guid=' + puid, $(nowNode).data().publisher.nickname);
    }
  });
}

//팝업 동의 서버 응답
function friendAgreeComp(_data) {
  if (_data.result == 'SUCCESS') {
    setTimeout(function() {
      friendAccept();
    }, 150);
  } else {
    alertOrModal(notiL10NData.ErrorOccurred); //'친구 동의 실패'
  }
}

function alertOrModal(_msg) {
  alert(_msg);
}

//초기 실행 -------------------------------------------------------------------------------------------
$(function() {
  if ($('.noticeList, #notiList').length < 1) return;
  init();
});

const notiFriends = {
  friendAgreePop: friendAgreePop,
};

export default notiFriends;
