import tmpl from './notiTemplate';
import api from './notiApi';

var isLoading = false;
var isFirst = true;
var pageNum = 0;
var pageTotal = 1;
var pageSize = 100;
var container = {};
var userDataLocale = 'ko-KR';
var loadingBar;

var $dispatcher = jQuery({ name: 'dispatcher' });

function init() {
  userDataLocale = window.userData && window.userData.locale ? window.userData.locale : 'ko-KR';
  jQuery('#notiList').append(jQuery(document.createElement('ul')));
  container = jQuery('#notiList ul');
  loadingBar = jQuery(tmpl.tmpLoading()).insertAfter(jQuery('#notiList .loading'));

  //알림 읽음 확인 -------------------------------------------------------------------------------------------
  jQuery('#notiList ul .wrapNotice')
    .off('click')
    .on('click', function(event) {
      var notiId = jQuery(this).data('notiid');
      if (jQuery(this).data('isnew')) {
        jQuery.ajax({
          url: api.confirm,
          dataType: 'jsonp',
          type: 'GET',
          data: { notiID: notiId },
          success: function() {},
          beforeSend: function() {},
          complete: function() {},
          error: function() {},
        });
      }
    });
}

//알림리스트 로딩-------------------------------------------------------------------------------------------
function notiListLoadStart() {
  if (isLoading) return;
  loadingBar.text(notiL10NData.Loading);
  pageNum++;

  const apiData = window.notiDummyData || api;
  var request = jQuery.ajax({
    url: apiData.list,
    dataType: 'jsonp',
    type: 'GET',
    jsonpCallback: 'callback',
    // url : 'http://static.plaync.co.kr/gnb/error/gnbNtError.js',	dataType : "jsonp", type : "GET", jsonpCallback: "callback",
    data: {
      targetCategory: 'GNB_NOTI',
      pageSize: pageSize,
      pageNo: pageNum,
      language: userDataLocale,
      https: window.location.protocol === 'https:',
    },
    beforeSend: function() {
      isLoading = true;
      loadingBar.show();
    },
    complete: function(jqXHR, textStatus) {
      isLoading = false;
      loadingBar.hide();

      $dispatcher.trigger('load-complete');
    },
  });

  request.done(listLoadComplete);
  request.fail(function(jqXHR, textStatus) {
    isLoading = false;
    loadingBar.hide();
    container.append(tmpl.tmpNoMsg(notiL10NData.ErrorOccurred));
  });
}

function listLoadComplete(_data) {
  var returnCode = parseInt(_data.result.returnCode, 10);
  if (returnCode === 0) {
    var totalCount = parseInt(_data.result.notiResult.totalCount, 10);
    if (totalCount <= 0) {
      pageTotal = 0;
      pageNum = 0;
      jQuery('.wrapNoticeBox').remove();
      container.after(tmpl.tmpNoMsg(notiL10NData.NoNewNotifications));
    } else {
      if (isFirst) {
        pageTotal = Math.floor(totalCount / pageSize) + 1;
        isFirst = false;
      }

      tmpl.setCurrentTime(_data.result.notiResult.serverMillisec);

      var newList;
      for (var i = 0, j = _data.result.notiResult.notiList.length; i < j; i++) {
        newList = tmpl.tmpMsg(_data.result.notiResult.notiList[i]);
        container.append(newList);
        imgError(newList, _data.result.notiResult.notiList[i]);
      }
    }
  } else {
    var msg;
    if (_data.result.returnCode === '401') {
      msg = notiL10NData.LoginRequired;
    } else if (_data.result.returnCode === '999') {
      msg = notiL10NData.Maintenance;
    } else {
      msg = notiL10NData.ErrorOccurred;
    }
    container.append(tmpl.tmpNoMsg(msg));
  }
}

function imgError(_cont, _obj) {
  var imgs = jQuery(_cont).find('.thumb img');
  if (imgs.length < 1) return;

  // 한번만 에러 이벤트 실행 (onErrorUrl 이미지도 없을 경우 무한루프돔!!)
  jQuery(imgs).one('error', function() {
    jQuery(this).attr('src', _obj.onErrorUrl);
    jQuery(this).attr('alt', '');
  });
}

function listLoadError(_data) {
  setTimeout(function() {
    container.empty();
    container.append(tmpl.tmpNoMsg(notiL10NData.Maintenance));
  }, 100);
}

var isFirstInit = true;
window.gnbNtError = listLoadError;

window.cnbNotiApi = api;
window.ncNotice = {
  $dispatcher,
  start() {
    if (isFirstInit || !jQuery('#notiList ul').length) {
      init();
      isFirstInit = false;
    }
    isFirst = true;
    pageTotal = 0;
    pageNum = 0;
    container.empty();
    notiListLoadStart();
  },
};
