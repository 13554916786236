import * as Util from './util';

class SwipeMove {
    constructor(_container, _itemSelector, _nowNum) {
        this.container = jQuery(_container);
        this.mover = this.container.children().eq(0);
        this.items = this.container.find(_itemSelector);
        this.itemLength = this.items.length;
        this.scrollerWidth = 0;
        this.targetX = 0;
        this.isTooBig = false;
        this.nowNum = _nowNum;

        this.isShowStatus = false;
        this.itemSelector = _itemSelector;

        if (this.mover.length) {
            this.setScroll(_itemSelector);
            this.winResize();
            this.addEvents();
        }
    }

    addEvents() {
        jQuery(window).on('resize.swipemove', e => this.winResize(e));
        this.mover.on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', e =>
            this.transitionDuration(this.mover),
        );
        if (!Util.isMobile) {
            this.container.on('mousedown', e => this.moveStart(e));
            jQuery(window).on('mousemove', e => this.moveMove(e));
            jQuery(window).on('mousecancel mouseup', e => this.moveEnd(e));
        } else {
            this.container.on('touchstart', e => this.moveStart(e));
            this.container.on('touchmove', e => this.moveMove(e));
            this.container.on('touchcancel touchend', e => this.moveEnd(e));
        }
    }

    winResize() {
        if (window.innerWidth < 960) {
            if (!this.isShowStatus) this.setScroll(this.itemSelector);
            this.isShowStatus = true;
        } else {
            this.isShowStatus = false;
        }
        this.isTooBig = this.container[0].clientWidth > this.scrollerWidth;
        this.resetPosition();
    }

    resetPosition() {
        let moveX = 0;
        if (this.isTooBig) {
            // moveX = (this.container[0].clientWidth - this.scrollerWidth) / 2;
            moveX = 1;
        } else {
            let item = jQuery(this.items[this.nowNum - 1]);
            if (item.length) moveX = -item.position().left;

            if (moveX < this.container[0].clientWidth - this.scrollerWidth) {
                moveX = this.container[0].clientWidth - this.scrollerWidth;
            }
            if (moveX > 0) {
                moveX = 0;
            }
        }

        this.transitionTimingFunction(this.mover);
        this.transitionDuration(this.mover, '200');
        this.translate(this.mover, moveX);
    }

    moveStart(e) {
        e.stopPropagation();
        this.isStart = true;
        let point = e.originalEvent.touches ? e.originalEvent.touches[0] : e.originalEvent;
        this.startX = point.pageX;
        this.tx = this.targetX;
    }

    moveMove(e) {
        e.stopPropagation();
        if (!this.isStart || this.isTooBig) return;
        this.mover.css({
            'pointer-events': 'none'
        });
        let point = e.originalEvent.touches ? e.originalEvent.touches[0] : e.originalEvent;
        let newX = this.tx + point.pageX - this.startX;
        this.translate(this.mover, newX);
    }

    moveEnd(e) {
        if (!this.isStart || this.isTooBig) return;
        this.isStart = false;
        this.mover.css({
            'pointer-events': 'auto'
        });

        if (this.targetX > 0) {
            this.transitionDuration(this.mover, '200');
            this.translate(this.mover, 0);
        }
        if (this.targetX < this.container[0].clientWidth - this.scrollerWidth) {
            this.transitionDuration(this.mover, '200');
            this.translate(this.mover, this.container[0].clientWidth - this.scrollerWidth);
        }
    }

    translate(_target, _x) {
        _target.css(Util.prefixStyle('transform'), 'translate3d(' + parseInt(_x) + 'px, 0, 0)');
        this.targetX = _x;
    }

    transitionDuration(target, time = 0) {
        target.css(Util.prefixStyle('transitionDuration'), time + 'ms');
    }

    transitionTimingFunction(target, easingFN = 'cubic-bezier(0.1, 0.54, 0.4, 1)') {
        target.css(Util.prefixStyle('transitionTimingFunction'), easingFN);
    }

    setScroll(_selector) {
        this.scrollerWidth = 0;
        // let selector = _selector.split(' ');
        // let parentWidth = 0;

        for (let i = 0; i < this.itemLength; i++) {
            this.scrollerWidth += Math.ceil(jQuery(this.items[i]).outerWidth());
            this.scrollerWidth += parseInt(jQuery(this.items[i]).css('margin-right'));
            this.scrollerWidth += parseInt(jQuery(this.items[i]).css('margin-left'));
        }

        /*if(selector.length > 1){
            for(let i = 0; i < selector.length - 1; i++){
                parentWidth += parseInt(this.mover.find(">" + selector[i]).css('padding-left'));
                parentWidth += parseInt(this.mover.find(">" + selector[i]).css('padding-right'));
                parentWidth += parseInt(this.mover.find(">" + selector[i]).css('margin-right'));
                parentWidth += parseInt(this.mover.find(">" + selector[i]).css('margin-right'));
                parentWidth += parseInt(this.mover.find(">" + selector[i]).css('border-right-width'));
                parentWidth += parseInt(this.mover.find(">" + selector[i]).css('border-left-width'));
            }
        }
        this.scrollerWidth += (parentWidth-15);*/

        this.mover.css({
            width: this.scrollerWidth + 2
        });
    }
}

export default SwipeMove;