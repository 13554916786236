import AbsModule from '../common/AbsModule';
import * as Util from '../common/util';

let uagentLow = navigator.userAgent.toLocaleLowerCase();
let isIos = /iphone|ipad/gi.test(uagentLow);
let isAndroid = /android/gi.test(uagentLow);

class AppDownload extends AbsModule {
  startSetting() {
    /*
    jQuery('#nc-cnb').on('click', '.ncc-appdownload .ncc-appdownload-btn', (e)=>{
        document.location.href = isAndroid? jQuery(e.currentTarget).data('andlink'): jQuery(e.currentTarget).data('ioslink');
        return false;
    });
    */
  }

  makeMarkup() {
    if (Util.isMobile) {
      var list = '';

      if (this.moduleData.appList && this.moduleData.appList.length > 0) {
        list = this.moduleData.appList.map(item => this.makeAppItem(item)).join('\n ');
      } else {
        for (let key in this.moduleData) {
          if (key == 'isShow' || key == 'appList') continue;
          list += this.makeAppItem(this.moduleData[key]);
        }
      }

      return `
            <div class="ncc-appdownload">
                <ul>${list}</ul>
            </div>
            `;
    } else {
      // return `<div class="ncc-appdownload"><ul style="display: none;"></ul></div>`;
      return ``;
    }
  }

  makeAppItem(_it) {
    let item = '';

    if (isIos && _it.iosAppLink) {
      item = `
            <li>
                <a class="ncc-appdownload-btn" href="${_it.iosAppLink}">
                    <img src="${_it.thumbImg}">
                    <strong>${_it.appName}</strong>
                </a>
            </li>`;
    }

    if (isAndroid && _it.androAppLink) {
      item = `
            <li>
                <a class="ncc-appdownload-btn" href="${_it.androAppLink}">
                    <img src="${_it.thumbImg}">
                    <strong>${_it.appName}</strong>
                </a>
            </li>`;
    }

    return item;
  }
}

export default AppDownload;
