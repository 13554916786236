import isString from 'lodash/isString';

import './lib/iscroll';
import './common/notiList';

import * as Util from './common/util';
import Header from './header/HeaderMain';
import Left from './left/LeftMain';
import Right from './right/RightMain';
import Character from './character/Character';
import Dimmed from './common/Dimmed';
import Common, { CNB_TYPE } from './common/Common';
import tmpl from './notice/notiTemplate';

/*
 * 경고!
 * babel core-js 3으로 트랜스파일.
 * ie에서 stackoverflow 간혹 발생. (반드시 ie에서 확인 후 배포)
 * playnclib 충돌을 피해 jQuery alias 사용
 * */
class App {
  constructor() {
    this.con = jQuery('#nc-cnb');
    this.orgClass = this.con.attr('class');
    this.com = Common.instance;
    this.dim = Dimmed.instance;
    this.com.init();

    this.deviceName = Util.isMobile ? 'mobile' : 'pc';
    this.browser = (Util.nowBrowser || '').toLowerCase();
  }

  start(_config) {
    this.startconfig = _config;
    //document.cookie =  'visitedGame=; expires=Thu, 01 Jan 1999 00:00:10 GMT;';

    this.destroy();
    if (typeof window.userData !== 'object') window.userData = { isLogin: false, locale: 'ko-KR' };

    // cnbData
    this.loadStartCnbData(window.userData.locale.slice(0, 2));
    /* jQuery.ajax({
      dataType: 'script',
      url: this.com.staticHost + '/uikit/cnb/data/cnbData-' + window.userData.locale.slice(0, 2) + '.js',
      success: () => {
        // nshop coupon
        const useNshopInstance = window.nshop && window.nshop.popup; // 이미 사용중
        if (!useNshopInstance) {
          jQuery.ajax({
            dataType: 'script',
            url: this.com.staticHost + '/nshop/common/popup/dist/js/popup-0.0.4-min.js',
            success: () => {},
          });
        }
        this.setting(_config);
      },
    }); */

  }

  loadStartCnbData(_loc){
    jQuery.ajax({ 
      dataType: 'script',
      url: this.com.staticHost + '/uikit/cnb/data/cnbData-'+_loc+'.js',
    })
    .done((_d)=> {
      this.loadCompleteCnbData();
    })
    .fail((_d)=> {
        console.log( 'err :', _d );
        this.loadStartCnbData('en');
    });
  }

  loadCompleteCnbData(){
    // nshop coupon
    const useNshopInstance = window.nshop && window.nshop.popup; // 이미 사용중
    if (!useNshopInstance) {
      jQuery.ajax({
        dataType: 'script',
        url: this.com.staticHost + '/nshop/common/popup/dist/js/popup-0.0.4-min.js',
        success: () => {},
      });
    }

    this.setting(this.startconfig);
  }

  destroy() {
    if (this.leftP) this.leftP.destroy();
    if (this.rightP) this.rightP.destroy();
  }

  setting(_config) {
    this.com.config = _config;

    this.con.attr('data-ncc-device', this.deviceName);

    if (this.com.env !== 'live') {
      this.con.attr('data-ncc-browser', this.browser);
    }

    this.con.attr('class', this.orgClass);
    this.con.addClass(this.com.theme.classes.theme);

    let isUserMenu = !(typeof this.com.config.useLoginMenu === 'undefined'
      ? true
      : this.com.config.useLoginMenu);
    if (isUserMenu) this.con.attr('data-disable-menu', '');

    if (this.com.config.type) this.con.attr('data-cnb-type', this.com.config.type.toLowerCase());
    if (this.com.config.theme && this.com.config.theme.colorScheme) {
      let scheme = this.com.config.theme.colorScheme;
      if (!isString(scheme)) {
        scheme = scheme.name;
      }
      this.con.attr('data-theme', scheme);
    }

    // iphoneX && chrome 체크!
    if (Util.isiPhoneX && navigator.userAgent.match('CriOS')) {
      this.con.addClass('iphonex-chrome');
    }

    if (this.com.config.type === CNB_TYPE.NONE) {
      this.setDefaultType();
    } else {
      let setCnbUI = this['set' + this.com.config.type + 'Type'];
      if (typeof setCnbUI === 'function') {
        setCnbUI.call(this);
      }
    }
  }

  resetSearchBar() {
    if (this.header) {
      this.header._search.hideSearchField();
    }
  }

  setDefaultType() {
    let header = new Header(this.com.config);
    let leftP = new Left(this.com.config);
    let rightP = new Right(this.com.config);
    let characterP = new Character(this.com.config, this.com.config.useCharChangeMenu);

    this.con.html(header.markUp + leftP.markUp + rightP.markUp + characterP.markUp);
    this.dim.init();

    header.start();
    leftP.start();
    rightP.start();
    characterP.start();

    this.leftP = leftP;
    this.rightP = rightP;
    this.header = header;
  }

  /*
  A : 게임 사이트
  */
  setAType() {
    this.setDefaultType();
  }

  /*
  B : 게임 프로모션, 업데이트 연대기 사이트
  - 서비스 전체보기, BI, 로그인 / 로그아웃
  */
  setBType() {
    /*this.con.addClass('nc-cnb--simple');
    let header = new Header( this.com.config );
    let leftP = new Left( this.com.config );

    this.con.html( header.markUp + leftP.markUp );
    this.dim.init();

    header.start();
    leftP.start();

    this.leftP = leftP;*/
    this.setDefaultType();
  }

  /*
  C : 회원 사이트 (마이페이지, 고객센터, N샵)
  */
  setCType() {
    this.setDefaultType();
  }

  /*
  D : 기타 사이트 (메인, 약관/정책, 공지사항, 장기 미이용 복귀 센터, 게임시간선택제)
  */
  setDType() {
    this.setDefaultType();
  }

  openCharacterPan() {
    this.dim.openClose(true, 'right');
    if (typeof window.ncNotice === 'object') window.ncNotice.start();
    jQuery('.ncc-character-panel').addClass('is-active');
    jQuery('#nc-cnb .ncc-profile-links a.ncc-profile-charchange').trigger('click');
  }

  message(msg) {
    jQuery(tmpl.alertMessage(msg))
      .modal()
      .on('hide.modal', () => {
        jQuery('#notice_alert').remove();
      });
  }
}

window.cnb = new App();
