(function(This, $) {
  var AppLink = {},
    userAgent = navigator.userAgent,
    isIphone = amI('iPhone') || amI('iPod'),
    isIpad = amI('iPad'),
    isAndroid = amI('Android'),
    nickname = '',
    pleaseInstall = function(_nickName) {
      return 'NC메신저를 설치하면\n' + _nickName + '님과 1:1 대화가 가능합니다.\nNC메신저를 설치해 보세요.';
    };

  function amI(device) {
    return new RegExp(device).test(userAgent);
  }

  AppLink.excute = function(url, nm) {
    nickname = nm;
    AppLink.install();

    if (isIphone || isIpad) {
      location.href = url;
    } else if (isAndroid) {
      var iframe = $('<iframe src="' + url + '" width="0" height="0" frameborder="0" />');
      iframe.appendTo('body');
      setTimeout(function() {
        iframe.remove();
      }, 1000);
    }

    return false;
  };

  AppLink.install = function() {
    var date = +new Date();

    setTimeout(function() {
      if (+new Date() - date < 1500) {
        (isIphone || isIpad) &&
          confirm(pleaseInstall(nickname)) &&
          (location.href = 'https://itunes.apple.com/kr/app/ncmesinjeo/id512119346');
        isAndroid &&
          confirm(pleaseInstall(nickname)) &&
          (location.href = 'market://details?id=com.ncsoft.android.apps.ncm');
      }
    }, 1000);
  };

  This.AppLink = AppLink;
})(window, jQuery);
