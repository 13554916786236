import AbsModule from '../common/AbsModule';
import lnbMaker from '../common/LnbMarkup';

class Lnb extends AbsModule {
  makeMarkup() {
    return `<nav class="ncc-lnb-m">${lnbMaker(this.moduleData.lnbData, false, this.moduleData.active)}</nav>`;
  }
}

export default Lnb;
