let isRc = location.href.match(/(rc\.|rc\-|rc2\-)/i) ? location.href.match(/(rc\.|rc\-|rc2\-)/i)[1] : '';
isRc = (isRc == 'rc2-') ? 'rc-' : isRc;
if(location.href.match(/(op\-|opdev\-)/i)) isRc = 'opdev-';
let nowDomain = /(asia\.nc\.com)/i.test(location.href) ? 'asia.nc.com' : 'noti.plaync.com';
let api = {
  count: location.protocol + '//' + isRc + nowDomain + '/noti/api/common/count.jsonp', //알림카운트
  list: location.protocol + '//' + isRc + nowDomain + '/noti/api/common/list.jsonp', //노티리스트
  confirm: location.protocol + '//' + isRc + nowDomain + '/noti/api/common/confirm.jsonp', //노티읽음 확인
};

if (/local\.|localhost|ui-static\.|file:/i.test(location.href)) {
  api = {
    count: 'http://ui-static.korea.ncsoft.corp/uikit/cnb/data/noticountdummydata.json',
    list: 'http://ui-static.korea.ncsoft.corp/uikit/cnb/data/notilistdummydata.json',
    confirm: '',
  };
}
export default api;


