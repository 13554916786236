import { isMobile } from './util';

function lnbMarkup(lnbData, isSub = false, active) {
  if (typeof lnbData === 'undefined') return '';

  const getClass = idx => {
    return !isSub
      ? 'ncc-lnb-item m' + (idx + 1) + (active.d1 === idx + 1 ? ' is-active' : '')
      : 's' + (idx + 1) + (active.d2 === idx + 1 ? ' is-active' : '');
  };

  const getListItem = (item, idx) => {
    const {
      iconType = '',
      title = '',
      url = '',
      popName = 'cnbPopup',
      popOption = '',
      popInPCOnly = '',
    } = item;

    let type = iconType;
    if (popInPCOnly === 'true' || popInPCOnly === true) {
      if (type === 'popup' && isMobile) {
        type = '';
      }
    }

    let props = `href="${item.url}"`; // Default
    let icon = '';

    switch (type) {
      case 'external':
        props += ` target="_blank"`;
        icon = `<em class="icon-external">external</em>`;
        break;
      case 'popup':
        props = `href="javascript:void(0)" onclick="window.open('${url}', '${popName}', '${popOption}').focus();return false;"`;
        icon = `<em class="icon-external">popup</em>`;
        break;
      case 'new':
        icon = `<em class="icon-new">N</em>`;
        break;
      default:
        props += ` target="_self"`;
        break;
    }

    const subList =
      item.sub && item.sub.length > 0
        ? lnbMarkup(
            item.sub,
            true,
            active.d1 === idx + 1
              ? active
              : {
                  d1: 0,
                  d2: 0,
                },
          )
        : '';

    return `<a ${props}><span>${title}</span>${icon}</a>${subList}`;
  };

  const list = lnbData
    .map((item, idx) => `<li class="${getClass(idx)}">${getListItem(item, idx)}</li>`)
    .join('\n ');

  return `<ul class="ncc-lnb-${isSub ? 'item__sub' : 'list'}">${list}</ul>`;
}

export default lnbMarkup;
