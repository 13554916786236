import _link from './notiLink';
import _thumb from './notiThumbnail';

function tmpMsg(_obj) {
  var link = _link.factory(_obj);

  var itemNode = $(document.createElement('li'));
  if (_obj.isNew) itemNode.addClass('on');
  itemNode.data(_obj);

  var iconUrl = _thumb.factory(_obj);
  var iconCls = /noti\/icon/gi.test(iconUrl) ? ' class="icon"' : '';

  var tmpl =
    '<div data-notiid="' +
    _obj.id +
    '"  data-isnew="' +
    _obj.isNew +
    '" class="wrapNotice' +
    (+_obj.msgType == 2 ? ' friendNt' : '') +
    '">' +
    (+_obj.msgType == 2 ? '' : '<a ' + link + '>') +
    '<p class="noticeMsg">' +
    _obj.msg +
    '</p>' +
    '<div class="thumb"><img' +
    iconCls +
    ' src="' +
    iconUrl +
    '" alt="' +
    _obj.publisher.nickname +
    '"></div>' +
    '<time pubdate="pubdate" class="noticeTime">' +
    makeTime(_obj.createMillisec) +
    '</time>' +
    (+_obj.msgType == 2 ? '' : '</a>') +
    '</div>';

  if (_obj.msgType == 2) {
    // 친구 신청일 경우 버튼 추가
    tmpl +=
      '<div class="wrapButton">' +
      '<button class="button btAllow imgBtn btnAllow">수락</button>&nbsp;' +
      '<button class="button btHidden imgBtn btnHide">숨기기</button>' +
      '</div>';
  }

  itemNode.append(tmpl);
  return itemNode;
}

function tmpLoading(msg) {
  return '<div class="loading">Loading...</div>';
}

function tmpNoMsg(msg) {
  return '<div class="wrapNoticeBox vAlign-middle wrap_nonedata"><p>' + msg + '</p></div>';
}

function tmpOkFriend(_obj) {
  var tmpl =
    '<div class="wrapNotice"><a>' +
    '<p class="noticeMsg">' +
    '<span class="gnbNtId">' +
    '<span class="gnbNtNickname">' +
    _obj.publisher.nickname +
    '</span>' +
    '<span class="gnbNtCharacter">' +
    (_obj.publisher.characterName
      ? '[' + _obj.publisher.characterName + '-' + _obj.publisher.serverName + ']'
      : '') +
    '</span>' +
    '</span>님의 친구 신청을 수락했습니다.' +
    '</p>' +
    '<div class="thumb"><img src="' +
    _obj.thumbnailUrl +
    '" alt="' +
    _obj.publisher.nickname +
    '" onerror="this.src=' +
    _obj.onErrorUrl +
    '"></div>' +
    '<time pubdate="pubdate" class="noticeTime">&#48169;&#44552;&#32;&#51204;</time>' +
    '</a></div>' +
    '<div class="tooltipMsg" style="display:none">친구가 되었습니다.</div>';
  return tmpl;
}

function friendAgree_M(_nick) {
  var tmpl =
    '<div id="friendAgree_M">' +
    '<section class="modalRelateFriend">' +
    '<button class="btnCloseLayer js_close">닫기</button>' +
    '<div class="wrapCont">' +
    '<dl>' +
    '<dt>NC친구가 되면?</dt>' +
    '<dd>서로의 B&S, 아이온 캐릭터를 조회할 수 있습니다.<br /><em>(단, <span class="nickname">닉네임</span>님이 비공개한 캐릭터는 조회 불가)</em></dd>' +
    '</dl>' +
    '<p class="js_udo"><span class="nickname">' +
    _nick +
    '</span>님의 <em>NC친구</em> 신청을<br> 수락하시겠습니까?</p>' +
    '</div>' +
    '<div class="wrapBtns js_udo">' +
    '<button class="yes">예</button>' +
    '<button class="no">아니오</button>' +
    '</div>' +
    '</section>' +
    '</div>';
  return tmpl;
}

function friendAgree_I(_nick) {
  var tmpl =
    '<div id="friendAgree_I" class="wrapLayer modalAgreeFriend">' +
    '<section class="msgLayer">' +
    '<header>' +
    '<h1>친구 추가</h1>' +
    '</header>' +
    '<section>' +
    '<p><strong class="username">' +
    _nick +
    '</strong>님의 친구 신청을 수락합니다.</p>' +
    '<dl>' +
    '<dt>친구가 되면?</dt>' +
    '<dd><em>서로의 아이온 캐릭터</em>를 조회할 수 있습니다.</dd>' +
    '<dd>리니지/리니지2의 경우 <em>신청/수락한 캐릭터만 조회</em>할 수 있습니다.</dd>' +
    '</dl>' +
    '<menu>' +
    '<button class="yesAgree">예, 친구 신청을 수락하겠습니다.</button><br>' +
    '<button class="noAgree js_close">아니오, 친구 신청을 수락하지 않겠습니다.</button>' +
    '</menu>' +
    '</section>' +
    '<div class="button">' +
    '<button class="js_close">닫기</button>' +
    '</div>' +
    '</section>' +
    '</div>';
  return tmpl;
}

function alertMessage(msg) {
  var tmpl =
    '<div id="notice_alert">' +
    '<section class="modalRelateFriend">' +
    '<div class="wrapCont">' +
    '<p style="border-top: 0; padding: 15px 0 25px 0;">' +
    msg +
    '</p>' +
    '</div>' +
    '<div class="wrapBtns js_udo">' +
    '<button class="js_close" data-dismiss="modal">확인</button>' +
    '</div>' +
    '</section>' +
    '</div>';
  return tmpl;
}

var currentTime;

function makeTime(_createMillisec) {
  var createTime = parseInt(_createMillisec);
  var createTime2 = new Date(createTime);
  var howTime = parseInt((currentTime - createTime) / 1000);
  var timeElm;

  if (howTime < 60) {
    timeElm = notiL10NData.AfewsecondsAgo;
  } else if (howTime >= 60 && howTime < 3600) {
    timeElm = parseInt(howTime / 60) + notiL10NData.MinutesAgo;
  } else if (howTime >= 3600 && howTime < 86400) {
    timeElm = parseInt(howTime / (60 * 60)) + notiL10NData.HhoursAgo;
  } else if (howTime >= 86400 && howTime < 604800) {
    timeElm = parseInt(howTime / (60 * 60 * 24)) + notiL10NData.DaysAgo;
  } else if (howTime >= 604800) {
    timeElm = createTime2.getFullYear() + '.' + (createTime2.getMonth() + 1) + '.' + createTime2.getDate();
  }
  return timeElm;
}

function setCurrentTime(_t) {
  currentTime = _t;
}

const tmpl = {
  tmpMsg: tmpMsg,
  tmpLoading: tmpLoading,
  tmpNoMsg: tmpNoMsg,
  tmpOkFriend: tmpOkFriend,
  setCurrentTime: setCurrentTime,
  friendAgree_M: friendAgree_M,
  friendAgree_I: friendAgree_I,
  alertMessage: alertMessage,
};

export default tmpl;
