import { getRandomInt } from '../common/util';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import keys from 'lodash/keys';
import isObject from 'lodash/isObject';
// import hexToRgba from 'hex-to-rgba';

/*import jss from 'jss';
import preset from 'jss-preset-default';
jss.setup(preset());*/

class Theme {
  constructor() {
    // this.__jss = new Jss('theme-' + getRandomInt(0, 9));
    this.__jss = new Jss('custom-theme');
  }

  update(config) {
    const biImg = config.bi.image || {};
    const header = config.header || {};

    let topBi = biImg.medium || biImg.top || biImg,
      lnbBi = biImg.lnb || biImg;

    const mobileBIStyle = this.getBIStyle(lnbBi);
    const mainBIStyle = this.getBIStyle(topBi, false);
    const subBIStyle = this.getBIStyle(lnbBi);
    // const stickyBIStyle = this.getBIStyle(lnbBi, false);

    const isWhiteTheme = config.type === 'C' || config.type === 'D';
    this.__style = {
      theme: {
        '&#nc-cnb .ncc-gnb-wrap .ncc-bi': mobileBIStyle,
        // '&#nc-cnb.is-sticky .ncc-gnb-wrap .ncc-bi': stickyBIStyle,
        '&#nc-cnb .ncc-lnb-header-bi .ncc-bi': subBIStyle,
        '&#nc-cnb .ncc-header': isWhiteTheme ? {} : { backgroundColor: header.medium || {} },
        '&#nc-cnb.is-sticky .ncc-header': isWhiteTheme ? {} : { backgroundColor: header.mediumSticky || {} },
        '&#nc-cnb .ncc-left-panel-header': isWhiteTheme ? {} : { backgroundColor: header.lnb || {} },
      },
      // subBi: subBIStyle,
      '@media (min-width: 960px)': {
        theme: {
          '&#nc-cnb .ncc-gnb-wrap .ncc-bi': mainBIStyle,
          '&#nc-cnb .ncc-header': isWhiteTheme ? {} : { backgroundColor: header.large || {} },
        },
      },
    };

    // lnb dropdown 셋팅
    if (!isEmpty(config.lnbDropdown)) {
      const { itemColor, itemBorderColor, itemHoverColor } = config.lnbDropdown;

      const dropdownTheme = {
        theme: {
          // '&#nc-cnb.is-sticky .ncc-dropdown .dropdown-toggle': { backgroundColor: itemHoverColor },
          '&#nc-cnb .ncc-dropdown .dropdown-item': {
            backgroundColor: itemColor,
            borderColor: itemBorderColor,
          },
          '&#nc-cnb .ncc-header .ncc-dropdown .dropdown-item:hover': {
            backgroundColor: itemHoverColor,
            borderColor: itemHoverColor,
          },
          '&#nc-cnb .ncc-left-panel .ncc-dropdown .dropdown-toggle': { backgroundColor: itemHoverColor },
        },
      };

      this.__style = merge(this.__style, dropdownTheme);
    }

    // theme 셋팅
    if (typeof config.theme !== 'undefined') {
      let customTheme = { theme: {} };

      // set colors
      const colorScheme = config.theme.colorScheme;
      if (isObject(colorScheme)) {
        if (colorScheme.default) {
          customTheme.theme['&#nc-cnb.is-sticky .ncc-header'] = customTheme.theme[
            '&#nc-cnb .ncc-left-panel-header'
          ] = customTheme.theme['&#nc-cnb .ncc-profile-img.ic-home::after'] = customTheme.theme[
            '&#nc-cnb .ncc-shortcut'
          ] = customTheme.theme['&#nc-cnb .ncc-userinfo:after'] = {
            backgroundColor: colorScheme.default,
          };
        }
        if (colorScheme.defaultOn) {
          customTheme.theme['&#nc-cnb .ncc-shortcut-item__link:hover'] = customTheme.theme[
            '&#nc-cnb .ncc-profile-img.ic-home:hover::after'
          ] = {
            backgroundColor: colorScheme.defaultOn,
          };
        }
        if (colorScheme.start) {
          customTheme.theme['&#nc-cnb .ncc-gamestart-btn__start::before'] = {
            backgroundColor: colorScheme.start,
          };
        }
        if (colorScheme.startOn) {
          customTheme.theme['&#nc-cnb .ncc-gamestart-btn__start:hover::before'] = {
            backgroundColor: colorScheme.startOn,
          };
        }
        if (isObject(colorScheme.subMenu)) {
          const { hover, bar, barImg } = colorScheme.subMenu;
          if (!isEmpty(hover)) {
            customTheme.theme['&#nc-cnb .ncc-lnb .ncc-lnb-item__sub li.is-active > a'] = { color: hover };
            customTheme.theme['&#nc-cnb .ncc-depth2-list-wrap .ncc-depth2-list-items.selected a'] = {
              color: hover,
            };
          }

          if (!isEmpty(bar)) {
            if (!isEmpty(barImg)) {
              let img = `url(${barImg}) no-repeat center 0`;
              customTheme.theme['&#nc-cnb .ncc-lnb .ncc-lnb-hover'] = { background: img };
            } else {
              let gradient = `linear-gradient(to left, rgba(0,0,0,0), ${bar}, white, ${bar}, rgba(0,0,0,0))`;
              customTheme.theme['&#nc-cnb .ncc-lnb .ncc-lnb-hover'] = { background: gradient };

              let selectedMenuDom = '&#nc-cnb .ncc-depth2-list-wrap .ncc-depth2-list-items.selected a';
              customTheme.theme[selectedMenuDom] = jQuery.extend(customTheme.theme[selectedMenuDom], {
                borderColor: bar,
              });
            }
          }
        }
      }

      // set style
      const style = config.theme.style;
      if (!isEmpty(style)) {
        customTheme = merge(customTheme, this.getCustomStyle(style));
      }

      this.__style = merge(this.__style, customTheme);
    }

    /*if(this.__sheet) {
        this.__sheet.detach();
    }

    this.__sheet = jss.createStyleSheet(this.__style);
    this.__sheet.attach();*/

    if (this.__jss.stylesheet !== '') {
      this.__jss.detach();
    }

    let stylesheet = this.__jss.createStyleSheet(this.__style);
    this.__jss.attach(stylesheet);
  }

  getCustomStyle(style) {
    const root = '#nc-cnb';

    let value = { theme: {} };
    let props = keys(style);
    jQuery.each(props, (i, o) => {
      if (/@media/gi.test(o)) {
        value[o] = this.getCustomStyle(style[o]);
      } else {
        const hasRootSelector = new RegExp('^' + root, 'i').test(o);
        const selector = hasRootSelector ? `&${o}` : `&${root} ${o}`;
        value.theme[selector] = style[o];
      }
    });

    return value;
  }

  getBIStyle(data, isExtend = true) {
    if (isEmpty(data)) {
      return {};
    }

    if (isEmpty(data.path)) {
      return data.style || {};
    }

    let img = {
      textIndent: '-9999em',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    };

    let styleObj = jQuery.extend({}, { backgroundImage: `url(${data.path})` }, data.style);

    if (isExtend) {
      // styleObj.extend = img;
      styleObj = jQuery.extend({}, styleObj, img);
    }

    return styleObj;
  }

  get style() {
    return this.__style;
  }

  get classes() {
    // return this.__sheet.classes;
    return { theme: this.__jss.name };
  }

  /*hexToRGB(hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16),
          g = parseInt(hex.slice(3, 5), 16),
          b = parseInt(hex.slice(5, 7), 16);

      if (alpha) {
          return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      } else {
          return "rgb(" + r + ", " + g + ", " + b + ")";
      }
  }*/
}

class Jss {
  constructor($name) {
    this._name = $name;
    this._stylesheet = '';

    this._uppercasePattern = /[A-Z]/g;
    this._msPattern = /^ms-/;
    this._cache = {};

    this.IS_UNITLESS = {
      animationIterationCount: true,
      boxFlex: true,
      boxFlexGroup: true,
      boxOrdinalGroup: true,
      columnCount: true,
      flex: true,
      flexGrow: true,
      flexPositive: true,
      flexShrink: true,
      flexNegative: true,
      flexOrder: true,
      gridRow: true,
      gridColumn: true,
      fontWeight: true,
      lineClamp: true,
      lineHeight: true,
      opacity: true,
      order: true,
      orphans: true,
      tabSize: true,
      widows: true,
      zIndex: true,
      zoom: true,

      // SVG-related properties
      fillOpacity: true,
      stopOpacity: true,
      strokeDashoffset: true,
      strokeOpacity: true,
      strokeWidth: true,
    };
  }

  get name() {
    return this._name;
  }

  get stylesheet() {
    return this._stylesheet;
  }

  attach(stylesheet) {
    this._stylesheet = stylesheet;
    this._stylesheetDom = jQuery(`<style type="text/css">${stylesheet}</style>`);
    this._stylesheetDom.appendTo(jQuery('head'));
  }

  detach() {
    if (this._stylesheetDom) {
      this._stylesheetDom.remove();
    }
  }

  createStyleSheet($obj) {
    let keys = Object.keys($obj);
    let i,
      len = keys.length;
    let result = '';

    for (i = 0; i < len; i++) {
      let key = keys[i];
      let val = $obj[key];

      if (key === 'theme') {
        result += this.createStyleSheet(val);
      } else if (/@media/gi.test(key)) {
        result += key + '{' + this.createStyleSheet(val) + '}';
      } else {
        let markup = this.createStyleSheetMarkup(val);
        if (markup !== '') {
          result += '.' + this._name + key.replace('&', '') + '{' + markup + '}';
        }
      }
    }

    return result;
  }

  createStyleSheetMarkup($style) {
    let keys = Object.keys($style);
    let i,
      len = keys.length;
    let result = '';

    for (i = 0; i < len; i++) {
      let key = keys[i];
      let val = $style[key];

      if (typeof val !== 'undefined' && !isObject(val)) {
        result += this.hyphenate(key) + ':' + this.addPx(key, val) + ';';
      }
    }

    return result;
  }

  hyphenate(string) {
    let cache = this._cache;
    let uppercasePattern = this._uppercasePattern;
    let msPattern = this._msPattern;
    return string in cache
      ? cache[string]
      : (cache[string] = string
          .replace(uppercasePattern, '-$&')
          .toLowerCase()
          .replace(msPattern, '-ms-'));
  }

  addPx(name, value) {
    if (typeof value === 'number' && !this.IS_UNITLESS[name]) {
      return value + 'px';
    } else {
      return value;
    }
  }
}

export default Theme;
