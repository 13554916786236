import AbsModule from '../common/AbsModule';
import NotiSetting from './NotiSetting';
import api from '../notice/notiApi';

class Noti extends AbsModule {
  startSetting() {
    this._notisetting = new NotiSetting(this.moduleData);

    jQuery('#nc-cnb .ncc-noti-list .wrap_btns button.btnSetting')
      .off('click')
      .on('click', () => {
        jQuery('#notiList').hide();
        jQuery('#notiSetting').show();
        this._notisetting.start();
      });

    if (this.moduleData.userData.isLogin) {
      this.loadNotiCount();
    }
  }

  loadNotiCount() {
    const apiData = window.notiDummyData || api;

    jQuery.ajax({
      url: apiData.count,
      dataType: 'jsonp',
      type: 'GET',
      jsonpCallback: 'callback',
      data: { 'targetCategory[]': 'GNB_NOTI' },
      success: _data => {
        if (_data.result.returnMessage == 'SUCCESS') {
          let countNum = parseInt(_data.result.notiResult.countList[0].notiCount);
          let isShow = countNum > 0;

          if (isShow) {
            let num = countNum > 99 ? '99+' : countNum;
            jQuery('#nc-cnb .ncc-login .ncc-login--info__noti span')
              .text(num)
              .css('display', 'inline-block');

            jQuery('#nc-cnb .ncc-noti-tab a span')
              .text(num)
              .css('display', 'inline-block');
            jQuery('.ncc-login--user-btn .noti-alarm-count')
              .text(num)
              .css('display', 'inline-block');
          } else {
            this.notiCountNumHide();
          }
        } else {
          this.notiCountNumHide();
        }
      },
      beforeSend: () => {},
      complete: () => {},
      error: () => {
        this.notiCountNumHide();
      },
    });
  }

  notiCountNumHide() {
    jQuery('#nc-cnb .ncc-login .ncc-login--info__noti span')
      .text(0)
      .hide();

    jQuery('#nc-cnb .ncc-noti-tab a span')
      .text(0)
      .hide();
    jQuery('.ncc-login--user-btn .noti-alarm-count').hide();
  }

  makeMarkup() {
    return `
        <div class="ncc-noti">
            <div class="ncc-noti-list" id="notiList">
                <div class="wrap_btns"><button class="imgBtn btnSetting">${cnbL10NData.Notifications.NotificationsSettings}</button></div>
            </div>
            <div class="ncc-noti-setting" id="notiSetting" style="display: none;">
            </div>
        </div>
        `;
  }
}

export default Noti;
