(function(exports, $) {
  'use strict';
  var $body = $('body'),
    $dimmed = $('<div id="dimmed" />').appendTo('body'),
    // $hash = $( exports.location.hash ),

    /*
     * Modal default options
     */
    defaults = {
      keyboard: !0,
      backdrop: !0,
      scroll: !0,
      click: !0,
      effect: 'fade',
    },
    /*
     * Modal Class definition
     */
    Modal = function(pane, options) {
      // merge defaults and options without modifying defaults
      this.options = $.extend({}, defaults, options);

      this.$pane = pane
        .addClass(this.options.effect)
        .on('click', '[data-dismiss="modal"]', $.proxy(this.hide, this))
        .bind('webkitTransitionEnd', function() {
          $(this)
            .focus()
            .find('[data-autofocus]')
            .focus();
        });

      this.options.remote &&
        this.$pane.load(this.options.remote, function() {
          pane.modal('show');
        });
    };

  Modal.prototype = {
    backdrop: function() {
      this.$backdrop = $('<div class="backdrop ' + this.options.effect + '">').appendTo($dimmed);
      this.options.click && this.$backdrop.one('click', $.proxy(this.hide, this));

      return this.$backdrop;
    },
    toggle: function() {
      this.$pane.modal(this.isShown ? 'hide' : 'show');
    },
    show: function() {
      if (this.options.backdrop && !this.isShown) {
        this.backdrop();
      }

      this.$pane
        .addClass('modal')
        .attr('tabindex', -1)
        .appendTo($dimmed)
        .show()
        .css({
          marginTop: Math.floor(-this.$pane.outerHeight() / 2),
          marginLeft: Math.floor(-this.$pane.outerWidth() / 2),
        })
        .addClass('in');

      this.$backdrop && this.$backdrop.addClass('in');
      this.esc();
      !this.options.scroll && this.setBodyOverflow('hidden');

      this.isShown = !0;
    },
    hide: function() {
      this.$pane.trigger($.Event('hide.modal'));

      this.$pane.removeClass('in').hide();
      this.$backdrop && this.removeBackdrop();
      this.esc();
      !this.options.scroll && this.setBodyOverflow();

      this.isShown = !1;
    },
    removeBackdrop: function() {
      this.$backdrop.remove();
      this.$backdrop = null;
    },
    esc: function() {
      if (!this.options.keyboard) {
        return;
      }

      if (!this.isShown) {
        $body.bind(
          'keyup.modal',
          $.proxy(function(e) {
            e.which === 27 && this.hide();
          }, this),
        );
      } else {
        $body.unbind('keyup.modal');
      }
    },
    setBodyOverflow: function(css) {
      css && (this.cacheBodyCssOverflow = $body.css('overflow'));
      $body.css('overflow', css || this.cacheBodyCssOverflow);
    },
  };

  /*
   * Get global modal options
   */
  typeof exports['___modalConf'] === 'object' && $.extend(!0, defaults, exports['___modalConf']);

  /*
   * Modal plugin definition
   */
  $.fn.modal = function(options) {
    return this.each(function() {
      var $this = $(this),
        data = $this.data('modal');

      !data && $this.data('modal', (data = new Modal($this, options)));

      typeof options === 'string'
        ? data[options]()
        : (!options || (options && !options.remote)) && data.show();
      /*
	            options && options.remote // 원격일 경우
	            options && !options.remote // 클릭해서 띄울 경우
	            !options // 생성한 경우
            */
    });
  };

  /*
   * Set body overflow
   */
  function setBodyOverflow(css) {
    $body.css('overflow', css);
  }

  /*
   * Modal data-api definition
   */
  $body.on('click', '[data-ui="modal"]', function(e) {
    var $this = $(this),
      href = $this.attr('href'),
      isRemote = !/^#/.test(href),
      $target = $(!isRemote ? href : '<div />'),
      options = $.extend(isRemote ? { remote: href } : {}, $this.data());

    $target.modal(options).one('hide.modal', function() {
      $this.focus();
    });

    e.preventDefault();
  });
  // $hash.length && $hash.hasClass( 'modal' ) && $hash.modal();
})(window, jQuery);
