import AbsModule from '../common/AbsModule';
import Common from '../common/Common';

class Search extends AbsModule {
  startSetting() {
    if (typeof this.moduleData.search === 'undefined') return;
    this.showSearchBar = false;

    jQuery('.ncc-search').click(function(e) {
      e.stopPropagation();
    });

    const me = this;
    jQuery('.ncc-search legend').on('click', function(e) {
      e.preventDefault();
      me.showSearchField();
    });

    jQuery('.ncc-search-close').on('click', () => {
      this.hideSearchField();
      return false;
    });

    if (typeof window.AutoSuggest === 'undefined') {
      const path = '/uikit/autosuggest/v1/js/AutoSuggest.js';
      let host = Common.instance.staticHost;

      const scripts = jQuery("script[src*='/autosuggest/']");
      if (scripts.length === 0) {
        jQuery.getScript(host + path, () => {
          me.setAutoSuggest();
        });
      } else {
        setTimeout(() => {
          me.setAutoSuggest();
        }, 100);
      }
    } else {
      me.setAutoSuggest();
    }
  }

  setAutoSuggest() {
    const searchData = this.moduleData.search;
    const autoSuggest = new AutoSuggest();
    const queryStr = searchData.queryPattern || '?';

    autoSuggest.init({
      selector: '#nccSuggestForm',
      service: searchData.service,
      alias: searchData.alias,
      size: searchData.size,
      useDelbtn: searchData.useDelbtn,
      addParam: searchData.addParam || {},
      submitCallback: function(_q) {
        const _href =
          searchData.destUrl +
          queryStr +
          jQuery.param({
            query: _q,
            // where : this.moduleData.search.where,
            // pos : this.moduleData.search.pos
            site: searchData.service,
          });
        location.href = _href;
      },
    });
  }

  resetSearchField() {
    jQuery('.ncc-search-input').val('');
    jQuery('.ncc-search-input-delete').hide();
  }

  showSearchField() {
    if (this.showSearchBar) return;
    jQuery('.ncc-search').addClass('is-active');
    jQuery('.ncc-search legend')
      .hide()
      .animateCss('fadeIn');
    jQuery('.ncc-lnb').addClass('search-active');

    window.setTimeout(() => {
      jQuery('.ncc-search-input')
        .click()
        .focus();
      // jQuery('.ncc-search-wrap').css('overflow', 'visible');
    }, 300);

    /*
    모바일 디바이스에서 키보트 show/hide를 감지하기 위한 편법..

    jQuery(document).ready(function(){
      var _originalSize = jQuery(window).width() + jQuery(window).height()
      jQuery(window).resize(function(){
        if(jQuery(window).width() + jQuery(window).height() != _originalSize){
          console.log("keyboard show up");
        }else{
          console.log("keyboard closed");
        }
      });
    });
    */

    jQuery(document).on('click.search', this.hideSearchField.bind(this));
    this.showSearchBar = true;
  }

  hideSearchField() {
    jQuery('.ncc-search').removeClass('is-active');
    jQuery('.ncc-search legend')
      .show()
      .animateCss('fadeIn');
    jQuery('.ncc-lnb').removeClass('search-active');
    // jQuery('.ncc-search-wrap').css('overflow', 'hidden');

    this.resetSearchField();
    jQuery(document).off('click.search');
    this.showSearchBar = false;
  }

  makeMarkup() {
    let positionCls = ' ncc-search--right-no';

    if (this.moduleData.useGameStart === 'gameStart') {
      positionCls = ' ncc-search--right-pc';
    }

    if (this.moduleData.useGameStart === 'mobileGameDown') {
      if (this.moduleData.mobileGameDown.showAndro && !this.moduleData.mobileGameDown.showIos)
        positionCls = ' ncc-search--right-mo1';
      if (!this.moduleData.mobileGameDown.showAndro && this.moduleData.mobileGameDown.showIos)
        positionCls = ' ncc-search--right-mo1';
      if (this.moduleData.mobileGameDown.showAndro && this.moduleData.mobileGameDown.showIos)
        positionCls = ' ncc-search--right-mo2';
    }

    if (window.userData.isLogin) {
      positionCls += ' is-login';
    }

    return `
<div class="ncc-search${positionCls}">
    <fieldset>
        <legend>Search</legend>
        <div class="ncc-search-wrap">
            <input class="ncc-search-close" type="button" value="취소" title="취소"/>
            ${this.makeForm()}
        </div>
    </fieldset>
</div>`;
  }

  makeForm() {
    const search = this.moduleData.search || {};
    let placeHolder = '통합검색';
    if (search.placeholder && search.placeholder !== '') {
      placeHolder = search.placeholder;
    }

    let formEle = `
            <form id="nccSuggestForm" method="get" action="" onsubmit="return false">
                <input id="nccSuggestInput" class="ncc-search-input" type="text" value="" placeholder="${placeHolder}" autocomplete="off" data-name="suggest_input" />
                <a href="#none" class="ncc-search-input-delete" onclick="return false;" value="X" title="삭제" data-name="suggest_delete"></a>
                <input id="nccSuggestSubmit" class="ncc-search-submit" type="button" value="GO" title="검색" data-name="suggest_submit" />
                <div id="nccSuggestWrap" class="ncc-suggest-list-wrap" data-name="suggest_wrap">
                    <div id="nccSuggestList" class="ncc-suggest-list" data-name="suggest_list">
                        <div data-name="suggest_scroll"><ul></ul></div>
                    </div>
                </div>
            </form>
            `;

    return formEle;
  }
}

export default Search;
