import AbsModule from '../common/AbsModule';
import UserInfo from './UserInfo';
import Noti from './Noti';
import { isMobile } from '../common/util';

class RightMain extends AbsModule {
  constructor(_config) {
    super(_config, _config.userData.isLogin);

    this._userInfo = new UserInfo(_config);
    this._noti = new Noti(_config);
  }

  startSetting() {
    this._userInfo.start();
    this._noti.start();

    window.ncNotice.$dispatcher.on('load-complete change-checked', () => {
      // console.log('notice!!');
      this.createIScroll();
    });

    const $panel = jQuery('.ncc-right-panel');
    if ($panel.length) {
      const panel = $panel[0];
      const stopEvtAction = e => {
        e.preventDefault();
      };
      panel.addEventListener('touchmove', stopEvtAction, { passive: false });
    }
  }

  createIScroll() {
    if (this.iScroll) {
      this.iScroll.refresh();
    } else {
      const isMo = isMobile;
      this.iScroll = new IScroll('.ncc-right-panel-wrap', {
        scrollbars: true,
        mouseWheel: true,
        interactiveScrollbars: true,
        shrinkScrollbars: 'scale',
        disableMouse: !isMo,
        disablePointer: !isMo,
        fadeScrollbars: true,
        // preventDefault: false,
        preventDefaultException: { tagName: /^(INPUT|TEXTAREA|BUTTON|SELECT|A|LI|SPAN|IMG|STRONG)$/ },
      });
    }
  }

  destroy() {
    if (this.iScroll) {
      this.iScroll.destroy();
      this.iScroll = null;
    }
  }

  makeMarkup() {
    let brtag = `<br><br><br>`;
    if($('body').hasClass('ANDROID')){
        brtag = `<br><br><br><br><br><br><br>`;
    }
    return `
		<div class="ncc-right-panel">
		    <div class="ncc-right-panel-wrap">
                <div>
                    ${this._userInfo.markUp}
                    <div class="ncc-noti-wrap">
                        <ul class="ncc-noti-tab">
                            <li class="is-active"><a href="#" onclick="return false;">${cnbL10NData.Common.Notifications} <span class="is-on">0</span></a></li>
                            <li><a href="#">${cnbL10NData.Friends.OnlineFriends}</a></li>
                        </ul>
                        ${this._noti.markUp}

                        ${brtag}
                    </div>
                </div>
		    </div>
		</div>`;
  }
}

export default RightMain;
