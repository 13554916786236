let singleton = null;
let singletonEnforcer = 'singletonEnforcer';

class Dimmed {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw 'Cannot--construct singleton';
    }
  }

  static get instance() {
    if (!singleton) {
      singleton = new Dimmed(singletonEnforcer);
    }
    return singleton;
  }

  init() {
    this.dimmed = jQuery('#nc-cnb .ncc-dimmed');

    if (this.dimmed.length < 1) {
      this.dimmed = jQuery('<div class="ncc-dimmed"></div>').appendTo('#nc-cnb');
    }

    this.dimmed.on('touchstart touchmove touchend', e => {
      // e.preventDefault();
      e.stopPropagation();
    });

    this.dimmed.off('click').on('click', e => {
      this.openClose(false);
      return false;
    });
  }

  openClose(_isOpen = true, _posi = 'left') {
    if (_isOpen) {
      jQuery('.ncc-' + _posi + '-panel').addClass('is-active');
      this.dimmed.addClass('is-active-' + _posi);
      jQuery('body').css({
        width: '100%',
        position: 'fixed',
        'margin-top': '-' + jQuery('body').scrollTop() + 'px',
      });
    } else {
      jQuery('.ncc-left-panel, .ncc-right-panel, .ncc-character-panel').removeClass('is-active');
      this.dimmed.removeClass('is-active-left is-active-right is-active-title-submenu');
      jQuery('.ncc-lnb-title--item').hide();

      let mTop = Math.abs(
        jQuery('body')
          .css('margin-top')
          .replace('px', ''),
      );
      jQuery('body').attr({ style: '' });
      jQuery('body').scrollTop(mTop);
    }
    jQuery('body').toggleClass('ncc-dimmed', _isOpen);

    jQuery(this).trigger('open', { show: _isOpen, pos: _posi });
  }
}

export default Dimmed;
