import Common from '../common/Common';

class NotiSetting {
  constructor(_config) {
    this.config = _config;
    this.com = Common.instance;
    this.container = jQuery('#notiSetting');
    this.isLoading = false;

    const { locale } = this.config.userData;
    const languages = ['ko-KR', 'en-US', 'zh-TW', 'ja-JP'];
    this.userLocale = languages.find(lang => {
      return lang.indexOf(locale) !== -1;
    });

    this.isRc = /(rc\.)/i.test(location.href) ? 'rc.' : '';
    this.isRc = /(rc\-)/i.test(location.href) ? 'rc-' : this.isRc;
    this.isRc = /(rc2\-)/i.test(location.href) ? 'rc-' : this.isRc;

    let thisSiteNum = { lineage: '27', bns: '24', aion: '26', rk: '178' };
    this.prior = !!thisSiteNum[this.config.userData.site] ? thisSiteNum[this.config.userData.site] : '';

    let nowDomain = /(asia\.nc\.com)/i.test(location.href) ? 'asia.nc.com' : 'noti.plaync.com';
    this.api = {
      list: location.protocol + '//' + this.isRc + nowDomain + '/noti/api/common/getReceiveSetting.jsonp',
      reject:
        location.protocol + '//' + this.isRc + nowDomain + '/noti/api/common/updateReceiveSetting.jsonp',
    };

    if (this.com.env === 'opdev') {
      this.api = {
        list: 'http://ui-static.korea.ncsoft.corp/uikit/cnb/data/notisettingdummydata.json',
        reject: 'http://ui-static.korea.ncsoft.corp/uikit/cnb/data/notisettingdummydata2.json',
      };
    }
  }


  start() {


    jQuery.ajax({
      url: this.api.list,
      data: { priorMainCategories: this.prior, language: this.userLocale },
      dataType: 'jsonp',
      type: 'GET',
      jsonpCallback: 'callback',
      success: _data => {
        if (_data.result.returnMessage === 'SUCCESS') {
          let temp =
            this.tmplHeader(_data.result.notiResult) +
            this.tmplCategoryList(_data.result.notiResult.mainCategoryList) +
            this.tmplBtns();
          this.container.html(temp);
          if (!_data.result.notiResult.receive) this.container.find('dl').hide();

          this.addEvents();
          if (window.ncNotice) {
            window.ncNotice.$dispatcher.trigger('load-complete');
          }
        }
      },
      beforeSend: () => {},
      complete: () => {},
      error: (xhr, status) => {},
    });
  }

  addEvents() {
    jQuery('#notiSetting .wrap_btns .btnApply')
      .off('click')
      .on('click', e => this.apply());

    jQuery('#notiSetting .wrap_btns .btnCancel')
      .off('click')
      .on('click', e => {
        if (window.ncNotice) {
          jQuery('#notiSetting').hide();
          jQuery('#notiList').show();
          window.ncNotice.start();
        }
      });

    jQuery('#notiSetting dl dt input:checkbox')
      .off('change')
      .on('change', e => {
        let tNode = jQuery(e.target);
        tNode
          .parents('dl')
          .eq(0)
          .toggleClass('checked', tNode.is(':checked'));

        window.ncNotice.$dispatcher.trigger('change-checked');
      });

    jQuery('#notiSetting header input:checkbox')
      .off('change')
      .on('change', e => {
        this.container.find('dl').toggle(jQuery(e.target).is(':checked'));

        window.ncNotice.$dispatcher.trigger('change-checked');
      });
  }

  apply() {
    if (this.isLoading) return;
    let list = '';
    let comma = ',';
    let elList = jQuery('#notiSetting input:checkbox');
    elList.each((idx, it) => {
      if (!jQuery(it).is(':checked')) {
        list += it.id + (idx == elList.length - 1 ? '' : comma);
      }
    });

    jQuery.ajax({
      url: this.api.reject + '?rejectedCategories=' + list,
      data: { language: this.userLocale },
      dataType: 'jsonp',
      type: 'GET',
      jsonpCallback: 'callback',
      success: _data => {
        if (typeof _data.result !== 'undefined' && _data.result.returnMessage === 'SUCCESS') {
          this.tmplSettingCompleteMsg(_data);
        } else {
          this.tmplSettingErrorMsg();
        }
      },
      beforeSend: () => {
        this.isLoading = true;
      },
      complete: () => {
        this.isLoading = false;
      },
      error: (xhr, status) => {
        this.tmplSettingErrorMsg();
      },
    });
  }

  tmplHeader(_data) {
    let temp =
      '<header class="setAll">' +
      '<input type="checkbox" id="' +
      _data.allCategoryNo +
      '" ' +
      (_data.receive ? 'checked' : '') +
      ' />' +
      '<label for="' +
      _data.allCategoryNo +
      '">' +
      _data.title +
      '</label>' +
      '<p class="desc">' +
      _data.tooltip +
      '</p>' +
      '</header>';
    return temp;
  }

  tmplCategoryList(_data) {
    let temp = '';
    for (let i = 0; i < _data.length; i++) {
      temp += this.tmplCategory(_data[i]);
    }
    return temp;
  }

  tmplCategory(_data) {
    let items = '';
    for (let i = 0; i < _data.subCategoryList.length; i++) {
      items += this.tmplItem(_data.subCategoryList[i]);
    }

    let temp =
      '<dl class="setDetail ' +
      (_data.receive ? 'checked' : '') +
      '">' +
      '<dt>' +
      '<input type="checkbox" id="' +
      _data.mainCategoryNo +
      '" ' +
      (_data.receive ? 'checked' : '') +
      ' /> ' +
      '<label for="' +
      _data.mainCategoryNo +
      '">' +
      _data.title +
      ' ' +
      notiL10NData.Notifications +
      '</label>' +
      '</dt>' +
      items +
      '</dl>';
    return temp;
  }

  tmplItem(_data) {
    let temp =
      '<dd>' +
      '<input type="checkbox" id="' +
      _data.subCategoryNo +
      '" ' +
      (_data.receive ? 'checked' : '') +
      '> ' +
      '<label for="' +
      _data.subCategoryNo +
      '" title="' +
      _data.tooltip +
      '">' +
      _data.title +
      '</label>' +
      '</dd>';
    return temp;
  }

  tmplBtns() {
    let temp =
      '<div class="wrap_btns">' +
      '<button class="imgBtn btnApply">' +
      notiL10NData.btn_confirm +
      '</button>&nbsp;' +
      '<button class="imgBtn btnCancel">' +
      notiL10NData.btn_cancel +
      '</button>' +
      '</div>';
    return temp;
  }

  tmplSettingCompleteMsg(_data) {
    let dat = _data.result.notiResult.serverDateTime;
    let dateStr =
      dat.substr(0, 4) +
      notiL10NData.Year +
      ' ' +
      dat.substr(4, 2) +
      notiL10NData.Month +
      ' ' +
      dat.substr(6, 2) +
      notiL10NData.Day +
      ',\n';
    let str = dateStr + notiL10NData.NotiSettingsChanged;

    let recList = _data.result.notiResult.adReceiveList;
    let rejList = _data.result.notiResult.adRejectList;
    let recStr = notiL10NData.AgreedToReceiveAdvertisements;
    let rejStr = notiL10NData.WithdrawnFromAgreementReceiveAdvertisements;
    let recTmp = '';
    let rejTmp = '';

    if (recList.length > 0) {
      recTmp += '\n\n';
      for (let i = 0; i < recList.length; i++) {
        let item = recList[i];
        recTmp += (i == 0 ? '' : '/') + item.mainCategory + '-' + item.subCategory;
      }
      recTmp += recStr;
    }

    if (rejList.length > 0) {
      rejTmp += '\n\n';
      for (let i = 0; i < rejList.length; i++) {
        let item = rejList[i];
        rejTmp += (i == 0 ? '' : '/') + item.mainCategory + '-' + item.subCategory;
      }
      rejTmp += rejStr;
    }

    str += recTmp + rejTmp;

    alert(str);
    if (window.ncNotice) {
      jQuery('#notiSetting').hide();
      jQuery('#notiList').show();
      window.ncNotice.start();
    }
  }

  tmplSettingErrorMsg() {
    let str = notiL10NData.ProcessisDelayed;
    alert(str);
  }
}

export default NotiSetting;
