import AbsModule from '../common/AbsModule';
import lnbMaker from '../common/LnbMarkup';

const $ = jQuery;

class Lnb extends AbsModule {
  constructor(_config) {
    super(_config);
    this.disabled = !$.isArray(_config.lnbData) || _config.lnbData.length === 0;
  }

  startSetting() {
    if (this.disabled) return;

    this.hoverBar = $('#nc-cnb .ncc-lnb-hover');
    this.hoverBarHalf = this.hoverBar.width() / 2;
    this.hoverBarPos = 50;

    const { active = {} } = this.moduleData;
    const isMain = !active.d1 && !active.d2;
    // console.log(active);
    let timeoutID = null;

    if (isMain) {
      $('.ncc-lnb').addClass('ncc-lnb-type--main');
    }

    // menu items
    $('#nc-cnb')
      .on('mouseenter', '.ncc-lnb .ncc-lnb-item', e => {
        if (isMain) {
          $('.ncc-lnb').addClass('ncc-lnb-type--main-over');
        }
        const $target = $(e.currentTarget);
        const $lnbList = $target.closest('.ncc-lnb-list');

        $lnbList.find(`li.m${active.d1}`).removeClass('is-active');
        $target.addClass('is-over');

        if (timeoutID) {
          clearTimeout(timeoutID);
          timeoutID = null;
        }
        const targetIndex = $target.index() + 1;
        if (targetIndex === active.d1) {
          this.hoverBarMove(targetIndex, active.d2);
        } else {
          this.hoverBarMove(targetIndex);
        }
        return false;
      })
      .on('mouseleave', '.ncc-lnb .ncc-lnb-item', e => {
        if (isMain) {
          $('.ncc-lnb').removeClass('ncc-lnb-type--main-over');
        }
        const $target = $(e.currentTarget);
        const $lnbList = $target.closest('.ncc-lnb-list');

        $target.removeClass('is-over');
        $lnbList.find(`li.m${active.d1}`).addClass('is-active');

        /*timeoutID = setTimeout(() => {
          this.hoverBarMove(active.d1, active.d2);
        }, 100);*/
        return false;
      });

    // sub items
    $('#nc-cnb')
      .on('mouseenter', '.ncc-lnb .ncc-lnb-item .ncc-lnb-item__sub li', e => {
        const $target = $(e.currentTarget);
        const $lnbList = $target.closest('.ncc-lnb-list');
        const $activeTarget = $lnbList.find(`li.m${active.d1} .ncc-lnb-item__sub li.s${active.d2}`);

        $activeTarget.removeClass('is-active');
        $target.addClass('is-active');

        if (timeoutID) {
          clearTimeout(timeoutID);
          timeoutID = null;
        }
        this.hoverBarMove($target.closest('.ncc-lnb-item').index() + 1, $target.index() + 1);
        return false;
      })
      .on('mouseleave', '.ncc-lnb .ncc-lnb-item .ncc-lnb-item__sub li', e => {
        const $target = $(e.currentTarget);
        const $lnbList = $target.closest('.ncc-lnb-list');
        const $activeTarget = $lnbList.find(`li.m${active.d1} .ncc-lnb-item__sub li.s${active.d2}`);

        $target.removeClass('is-active');
        $activeTarget.addClass('is-active');

        timeoutID = setTimeout(() => {
          this.hoverBarMove(active.d1, active.d2);
        }, 30);
        // return false;
      });

    this.hoverBarMove(active.d1, active.d2);
  }

  hoverBarMove(_d1 = 0, _d2 = 0) {
    const el = $(`#nc-cnb .ncc-lnb .ncc-lnb-list li.m${_d1} .ncc-lnb-item__sub li.s${_d2}`);

    const hoverBarScale = el.length ? (((94 * el.width()) / 100) * 0.01 * 1.4).toFixed(2) : 0.1;
    let hoverBarOpacity = el.length ? 1 : 0;

    if (el.length && el.offset().left > 0) {
      const elCenterPos = el.offset().left + el.width() / 2;
      this.hoverBarPos = parseInt(elCenterPos - this.hoverBarHalf, 10);
    }

    if (this.hoverBar) {
      this.hoverBar.css({
        transform: `translateX(${this.hoverBarPos}px) scaleX(${hoverBarScale})`,
        opacity: hoverBarOpacity,
      });
    }
  }

  makeMarkup() {
    return `
        <nav class="ncc-lnb">
            ${lnbMaker(this.moduleData.lnbData, false, this.moduleData.active)}
            <span class="ncc-lnb-hover"></span>
        </nav>`;
  }
}

export default Lnb;
