function setTrace(depth) {
  try {
    _trk_clickTrace('EVT', depth);
  } catch (_e) {}
}

function trim(_str) {
  return _str.replace(/^\s+/, '').replace(/\s+$/, '');
}

function cutStrByIndex(_str, _cutStringIndex, _cutAddStr = '...') {
  let tempStr = _str;
  if (_str.length > _cutStringIndex) tempStr = _str.substr(0, _cutStringIndex) + _cutAddStr;
  return tempStr;
}

function reEscape(_str) {
  return _str
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'");
}

const nowBrowser = (() => {
  let agt = navigator.userAgent.toLowerCase();
  let trident = agt.match(/trident\/(\d.\d)/i);

  if (trident !== null) {
    if (trident[1] === '7.0') return 'IE' + 11;
    if (trident[1] === '6.0') return 'IE' + 10;
    if (trident[1] === '5.0') return 'IE' + 9;
    if (trident[1] === '4.0') return 'IE' + 8;
  }

  if (navigator.appName === 'Microsoft Internet Explorer') return 'IE' + 7;

  if (agt.indexOf('edge') !== -1) return 'Edge';
  if (agt.indexOf('chrome') !== -1) return 'Chrome';
  if (agt.indexOf('opera') !== -1) return 'Opera';
  if (agt.indexOf('staroffice') !== -1) return 'Star Office';
  if (agt.indexOf('webtv') !== -1) return 'WebTV';
  if (agt.indexOf('beonex') !== -1) return 'Beonex';
  if (agt.indexOf('chimera') !== -1) return 'Chimera';
  if (agt.indexOf('netpositive') !== -1) return 'NetPositive';
  if (agt.indexOf('phoenix') !== -1) return 'Phoenix';
  if (agt.indexOf('firefox') !== -1) return 'Firefox';
  if (agt.indexOf('safari') !== -1) {
    return agt.match('crios') ? 'Chrome' : 'Safari';
  }
  if (agt.indexOf('skipstone') !== -1) return 'SkipStone';
  if (agt.indexOf('netscape') !== -1) return 'Netscape';
  if (agt.indexOf('mozilla/5.0') !== -1) return 'Mozilla';
})();

const isMobile = /(android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|NC Noob)/i.test(
  navigator.userAgent.toLowerCase(),
);

const isiPhoneX = (function() {
  // Really basic check for the ios platform
  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // Get the device pixel ratio
  const ratio = window.devicePixelRatio || 1;

  // Define the users device screen dimensions
  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio,
  };

  // iPhone X Detection
  return iOS && screen.width === 1125 && screen.height === 2436;
})();

const isVideoSupport = nowBrowser != 'IE7' && nowBrowser != 'IE8' && !isMobile;

const movExt = nowBrowser == 'Chrome' ? '.webm' : '.mp4';

const vendor = (() => {
  const vendors = ['transform', 'webkitTransform', 'MozTransform', 'msTransform', 'OTransform'];

  for (let i = 0, max = vendors.length; i < max; i++) {
    if (vendors[i] in document.createElement('div').style) {
      return vendors[i].substr(0, vendors[i].length - 9);
    }
  }
  return false;
})();

function prefixStyle(style) {
  if (vendor === false) return false;
  if (vendor === '') return style;

  return vendor + style.charAt(0).toUpperCase() + style.substr(1);
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// object to css
/*const uppercasePattern = /[A-Z]/g;
const msPattern = /^ms-/;
const cache = {};

function hyphenateStyleName(string) {
    return string in cache
        ? cache[string]
        : cache[string] = string
            .replace(uppercasePattern, '-$&')
            .toLowerCase()
            .replace(msPattern, '-ms-');
}

const IS_UNITLESS = {
    animationIterationCount: true,
    boxFlex: true,
    boxFlexGroup: true,
    boxOrdinalGroup: true,
    columnCount: true,
    flex: true,
    flexGrow: true,
    flexPositive: true,
    flexShrink: true,
    flexNegative: true,
    flexOrder: true,
    gridRow: true,
    gridColumn: true,
    fontWeight: true,
    lineClamp: true,
    lineHeight: true,
    opacity: true,
    order: true,
    orphans: true,
    tabSize: true,
    widows: true,
    zIndex: true,
    zoom: true,

    // SVG-related properties
    fillOpacity: true,
    stopOpacity: true,
    strokeDashoffset: true,
    strokeOpacity: true,
    strokeWidth: true
};

function addPx(name, value) {
    if(typeof value === 'number' && !IS_UNITLESS[ name ]) {
        return value + 'px';
    } else {
        return value;
    }
}

function createMarkup(obj) {
    let keys = Object.keys(obj);
    if (!keys.length) return '';
    let i, len = keys.length;
    let result = '';
    for (i = 0; i < len; i++) {
        let key = keys[i];
        let val = obj[key];
        result += hyphenateStyleName(key) + ':' + addPx(key, val) + ';'
    }
    return result;
}*/

// jquery plugin
jQuery.fn.extend({
  animateCss: function(animationName, callback) {
    var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
    this.addClass('animated ' + animationName).one(animationEnd, function() {
      jQuery(this).removeClass('animated ' + animationName);
      if (callback) {
        callback();
      }
    });
    return this;
  },
});

export {
  movExt,
  setTrace,
  trim,
  cutStrByIndex,
  nowBrowser,
  isiPhoneX,
  isVideoSupport,
  isMobile,
  vendor,
  prefixStyle,
  reEscape,
  getRandomInt,
};
