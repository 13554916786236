import Theme from './theme';
import isEmpty from 'lodash/isEmpty';

export const CNB_TYPE = {
  NONE: '',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
};

/*
 * CNBV TYPe
 *
 * A : 게임 사이트
 * B : 게임 프로모션, 업데이트 연대기 사이트
 * C : 회원 사이트 (마이페이지, 고객센터, N샵)
 * D : 기타 사이트 (메인, 약관/정책, 공지사항, 장기 미이용 복귀 센터, 게임시간선택제)
 *
 */

let singleton = null;
let singletonEnforcer = 'singletonEnforcer';

class Common {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw 'Cannot--construct singleton';
    }
  }

  static get instance() {
    if (!singleton) {
      singleton = new Common(singletonEnforcer);
    }
    return singleton;
  }

  init() {
    this.__isMobile = /(iPhone|iPod|iPad|Android|NC Noob)/i.test(navigator.userAgent);
    const href = window.location.href;

    const subdomain = {
      opdev: ['op.', 'opdev.', 'op-', 'opdev-', 'local.', 'localhost', 'ui-static', 'file:'],
      rc: ['rc.', 'rc-', 'rc2-'],
      sb: ['sb.', 'sb-'],
    };

    this.__env = 'live';
    for (let prop in subdomain) {
      const matchs = subdomain[prop];
      const regexp = new RegExp(`^http[s]?:\\/\\/(${matchs.join('|')})+.*`, 'i');
      if (regexp.test(href)) {
        this.__env = prop;
      }
    }

    const staticurls = {
      opdev: 'https://op-wstatic.ncsoft.com',
      rc: 'https://rc-wstatic.plaync.co.kr',
      sb: 'https://sb-wstatic.plaync.com',
      live: 'https://wstatic-cdn.plaync.com',
    };
    this.__staticHost = staticurls[this.__env];

    this.__config = {};
    this.__defaultConfig = {
      type: '',
      bi: {},
    };
  }

  setShortCutData(data, config) {
    if (typeof config === 'undefined') return data;

    let resetData = {};
    jQuery.each(data, key => {
      if (typeof config[key] !== 'undefined') {
        resetData[key] = config[key];
      }
    });

    // check url
    jQuery.each(resetData, (key, obj) => {
      if (typeof obj === 'object') {
        if (isEmpty(obj.url)) {
          obj.url = undefined;
        }
      }
    });

    return jQuery.extend(true, {}, data, resetData);
  }

  setShortCutUrl(data, config) {
    jQuery.each(data, key => {
      const { url } = data[key];

      if (url && config[key]) {
        const cUrl = config[key].url;
        if (cUrl === '' || typeof cUrl === 'undefined') {
          config[key].url = url;
        }
      }
    });

    return config;
  }

  get staticHost() {
    return this.__staticHost;
  }

  get isMobile() {
    return this.__isMobile;
  }

  get env() {
    return this.__env;
  }

  set config(_config) {
    // let isPc = !this.__isMobile;
    // let isRcDash = (this.__env === 'rc') ? 'rc-' : '';
    // let isRc2Dash = (this.__env === 'rc') ? 'rc2-' : '';

    // default 설정 : 아래 4개의 숏컷은 항상 노출되도록 isShow: true 강제로 지정한다.
    this.__shortCutData = {
      isShow: true,
      charHome: { isShow: true, url: '/my/profile/character' },
      mypage: {
        isShow: true,
        type: '',
        // url: 'https://' + isRcDash + 'id.plaync.com/index'
        url: '',
      },
      ncoin: {
        isShow: true,
        type: 'popup',
        popOption: 'width=480,height=650,toolbar=0,menubar=0',
        // url: 'https://' + isRc2Dash + 'ncoin.plaync.com/ncoin/charge'
        url: '',
      },
      enterCoupon: {
        isShow: true,
        type: 'function',
        name: 'nshop.popup',
        params: { popup_service: 'nshop_coupon' },
      },
      cs: {
        isShow: true,
        type: '', // external
        // url: isPc ? 'https://' + isRcDash + 'cs.plaync.com/inquiry/list/view' : 'https://' + isRcDash + 'mcs.plaync.com/info'
        url: '',
      },
        security: {
            isShow: false,
            type: '', // external
            // url: isPc ? 'https://' + isRcDash + 'cs.plaync.com/inquiry/list/view' : 'https://' + isRcDash + 'mcs.plaync.com/info'
            url: '',
        },
    };

    this.__config = jQuery.extend(true, {}, this.__defaultConfig, _config);
    this.__config.type = (this.__config.type || this.__config.cnbType || '').toUpperCase();
    this.__config.userData = jQuery.extend(
      true,
      {},
      {
        accountState: { case: 0, msg: '' },
      },
      window.userData,
    );
    this.__config.serviceListData = window.cnbServiceListData;
    this.__config.bannerData = window.cnbBannerData;

    // defalut 설정 덮어씌우기.
    this.__shortCutData = this.setShortCutData(this.__shortCutData, _config.shortCut);

    // this.__config.shortCut = jQuery.extend(true, {}, window.cnbShortcutData, this.__shortCutData);
    // cnbShortcutData의 url만 체크해서 가져오자
    this.__config.shortCut = this.setShortCutUrl(window.cnbShortcutData, this.__shortCutData);

    // simpleCNB 속성 예외처리
    if (this.__config.simpleCNB) {
      this.__config.type = 'B';
    }

    switch (this.__config.type) {
      case CNB_TYPE.A:
        break;
      case CNB_TYPE.B:
        this.__config.useJoinMenu = false;
        this.__config.lnbData = [];
        if (this.__config.search) this.__config.search.isShow = false;
        this.__config.useGameStart = 'false';
        break;
      case CNB_TYPE.D:
        this.__config.lnbData = [];
      case CNB_TYPE.C:
        this.__config.useGameStart = 'false';
        if (this.__config.search) this.__config.search.isShow = false;
        break;
    }

    // set theme...
    if (typeof this.__theme === 'undefined') {
      this.__theme = new Theme();
    }
    if (this.__config.type === 'C' || this.__config.type === 'D') {
      this.__config.header = {};
    }
    this.__theme.update(this.__config);
  }

  get config() {
    return this.__config;
  }

  get theme() {
    return this.__theme;
  }
}

export default Common;
