var isRc = /(rc.)/i.test(location.href) ? 'rc.' : '';
var isHttps = location.protocol == 'https:' && location.href.indexOf('https:') > -1;
var api = {};

function init() {
  var path = 'https://wstatic-cdn.plaync.com';
  if (!isHttps) {
    path = 'http://static.plaync.co.kr';
  }
  api = {
    staticUrl: path + '/resource/noti/icon/',
    aionFile: 'http://' + isRc + 'gamepic.plaync.com/images/0301/',
  };
}

function aionChar(_serverId, _charId) {
  if (isHttps || typeof _serverId == 'undefined' || typeof _charId == 'undefined') {
    return api.staticUrl + 'aionprofile.jpg';
  } else {
    var char4 = _charId.substring(_charId.length - 4);
    return api.aionFile + _serverId + '/' + char4 + '/' + _charId + '.jpg';
  }
}

function factory(_obj) {
  var img;

  if (_obj.msgType == 2) {
    if (_obj.publisher.gameID == '3') {
      img = aionChar(_obj.publisher.serverID, _obj.publisher.characterID); // 친구신청 / 아이온
    } else if (_obj.publisher.gameID == '4') {
      img = api.staticUrl + 'l1profile.gif'; // 친구신청 / L1
    } else if (_obj.publisher.gameID == '5') {
      img = api.staticUrl + 'l2profile.gif'; // 친구신청 / L2
    } else {
      img = _obj.thumbnailUrl; // 친구신청 / 통합계정
    }
  } else {
    img = _obj.thumbnailUrl;
  }

  return img;
}

init();

const _thumb = {
  factory: factory,
};

export default _thumb;
