import AbsModule from '../common/AbsModule';

class GameStart extends AbsModule {
  makeMarkup() {
    let tmpl = '';

    if (this.moduleData.useGameStart === 'gameStart') {
      tmpl = `<div class="ncc-gamestart-btn">${this.moduleData.gameStart.markUp}</div>`;
    } else if (this.moduleData.useGameStart === 'mobileGameDown') {
      tmpl = `
<div class="ncc-gamestart-app">
    ${
      this.moduleData.mobileGameDown.showAndro
        ? `<a href="${this.moduleData.mobileGameDown.androAppLink}" class="android"><span>android downlaod</span></a>`
        : ''
    }
    ${
      this.moduleData.mobileGameDown.showIos
        ? `<a href="${this.moduleData.mobileGameDown.iosAppLink}" class="ios"><span>ios downlaod</span></a>`
        : ''
    }
</div>`;
    }

    return `<div class="ncc-gamestart">${tmpl}</div>`;
  }
}

export default GameStart;
